import { type RouteRecordRaw } from "vue-router"

import { type Permission } from "@/types"
const routes: Array<RouteRecordRaw> = [
  {
    path: "/analyse/",
    name: "AnalysisPage",
    component: () => import("./analysis/AnalysisBasePage.vue"),
    meta: {
      requiresInstitutionContext: true,
      requiredRoles: <Permission[]>[
        "appointment:list",
        "booking:list",
        "child:list",
        "employee:list",
        "employment:list",
        "qualification:list",
      ],
    },
    children: [
      {
        path: "",
        name: "RelevantFundingAnalysis",
        component: () => import("./analysis/RelevantFundingAnalysis.vue"),
      },
      {
        path: "faktor",
        name: "FactorAnalysis",
        component: () => import("./analysis/FactorAnalysis.vue"),
      },
      {
        path: "bezirksfoerderung",
        name: "DistrictFundingAnalysis",
        component: () => import("./analysis/DistrictFundingAnalysis.vue"),
      },
      {
        path: "anstellungsuebersicht",
        name: "EmploymentAnalysis",
        component: () => import("./analysis/EmploymentAnalysis.vue"),
      },
      {
        path: "belegungsanalyse",
        name: "OccupancyAnalysis",
        component: () => import("./analysis/OccupancyAnalysis.vue"),
      },
      {
        path: "beitragsanalyse",
        name: "ContributionAnalysis",
        component: () => import("./analysis/ContributionAnalysis.vue"),
      },
    ],
  },
]

export default routes
