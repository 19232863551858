<script
  setup
  lang="ts"
  generic="TEntity extends BaseEntity, TMappedEntity extends BaseEntity = TEntity"
>
import { computed, ref, toRef, watch, useAttrs } from "vue"
import { useI18n } from "vue-i18n"
import { RouteLocationMatched, RouteParamsRawGeneric, useRoute, useRouter } from "vue-router"

import type { BaseDialogProps } from "./types"
import type { BaseEntity } from "@/types"

import DynamicFormOverlay from "@/components/common/DynamicFormOverlay.vue"
import { useSessionStore } from "@/store/session"
import { useTableItemNavigation } from "@/utils/composables/useTableItemNavigation"

const props = withDefaults(defineProps<BaseDialogProps<TEntity, TMappedEntity>>(), {
  paramName: "id",
  mapViewRouteParams: (item: TMappedEntity) => ({ id: item.id }),
  dirty: false,
  isEditing: false,
  readonly: false,
  loading: false,
  width: "50vw",
  breakpoints: () => ({ "1250px": "60vw", "990px": "85vw", "768px": "100vw" }),
  routed: true,
  isCreateDialog: false,
})

const emit = defineEmits<{
  (e: "submit", event: () => any): any
  (e: "resetForm", event: { values: any }): void
  (e: "update:visible", value: boolean): void
  (e: "after-hide"): void
}>()

const attrs = useAttrs()

const sessionStore = useSessionStore()
const { t: $t } = useI18n()
const route = useRoute()
const router = useRouter()

const id = computed(() => route.params[props.paramName] as string)
const currentItem = computed(() => props.list?.find((item) => item.id === id.value))
watch(currentItem, (item) => item && resetForm(item), { immediate: true })

const dataTableItems = computed(() => props.dataTableCache?.items ?? [])

const visible = ref(true)

const routed = computed(() => !Object.keys(attrs).includes("visible"))

const hasUpdateRole = computed(() => {
  if (!props.resourceType) return true
  return sessionStore.hasRoles([`${props.resourceType}:update`])
})

const disabled = computed(() => !!id.value && !hasUpdateRole.value)

const header = computed(() => {
  const { header, readonly, localePrefix, isEditing, isCreateDialog } = props
  if (header) return header
  if (readonly) return $t(`${localePrefix}.dialog_view_header`)
  if (isCreateDialog) return $t(`${localePrefix}.dialog_create_header`)
  if (id.value || isEditing) return $t(`${localePrefix}.dialog_edit_header`)
  return $t(`${localePrefix}.dialog_create_header`)
})

const { nextRecord, previousRecord } = useTableItemNavigation(
  id,
  toRef(() => props.dataTableCache?.items || []),
  (item: TMappedEntity) => router.replace({ params: props.mapViewRouteParams(item) })
)

function resetForm(item: TMappedEntity) {
  emit("resetForm", { values: structuredClone(item) })
}

function extractRouteParams(
  path: RouteLocationMatched | undefined
): RouteParamsRawGeneric | undefined {
  if (!path) return undefined

  const matchedRoute = router.resolve(path)

  if (matchedRoute && matchedRoute.matched.length) {
    return matchedRoute.params
  }
}

async function close() {
  if (!routed.value) return
  const parentRoute = route.matched.at(-2)
  router.push({
    name: parentRoute?.name,
    params: extractRouteParams(parentRoute),
  })
}
</script>

<template>
  <DynamicFormOverlay
    :header="header"
    :visible="visible"
    :breakpoints="breakpoints"
    :width="width"
    :loading="loading"
    :mock="mock"
    :readonly="readonly"
    :is-editing="!!id || isEditing"
    :dirty="dirty"
    :submit-disabled="disabled"
    :hide-action-buttons="readonly"
    :hide-navigation-buttons="!currentItem || dataTableItems.length <= 1"
    @submit:form="$emit('submit', () => (visible = false))"
    @update:visible="[(visible = $event), $emit('update:visible', $event)]"
    @after-hide="[close(), $emit('after-hide')]"
    @next-record="nextRecord"
    @previous-record="previousRecord"
  >
    <template #subHeader>
      <slot name="subHeader"></slot>
    </template>

    <template #default>
      <slot :disabled="disabled"></slot>
    </template>
  </DynamicFormOverlay>
</template>

<style lang="scss" scoped>
:deep(.grid) {
  margin: 0 !important;
  padding: 0 !important;
}
</style>
