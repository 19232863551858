import {
  InstitutionType,
  IntegrativePlace,
  PlaceNecessity,
  type ProjectInstitution,
} from "@/graphql/types"
import { i18n } from "@/i18n"
import { type MappedProjectInstitution } from "@/store/institution"
import { type TableConfig } from "@/types"
import { getOptions } from "@/utils/composables/useDropdown"

export const OrganizationTypes = {
  "1.1": "1.1",
  "1.2": "1.2",
  "1.3": "1.3",
  "1.4": "1.4",
  "2.1": "2.1",
  "2.2": "2.2",
  "2.3": "2.3",
  "2.4": "2.4",
  "2.5": "2.5",
  "2.6": "2.6",
  "2.7": "2.7",
  "2.8": "2.8",
  "2.9": "2.9",
  "3.1": "3.1",
  "3.2": "3.2",
  "3.3": "3.3",
}

export const LegalForms = {
  "01": "01",
  "02": "02",
  "03": "03",
  "04": "04",
  "05": "05",
  "06": "06",
  "07": "07",
  "08": "08",
  "09": "09",
  "10": "10",
  "11": "11",
  "12": "12",
  "13": "13",
}

export const sectionTypeOptions = getOptions(InstitutionType, "institution_type")
export const organizationTypeOptions = getOptions(
  OrganizationTypes,
  "admin.einrichtungen.view.statistics.organization_types"
)
export const legalFormOptions = getOptions(
  LegalForms,
  "admin.einrichtungen.view.statistics.legal_forms"
)
export const placeNecessityOptions = getOptions(
  PlaceNecessity,
  "admin.einrichtungen.view.openingTimes_places.children_occupy_places_options"
)
export const integrativePlaceOptions = getOptions(
  IntegrativePlace,
  "admin.einrichtungen.view.openingTimes_places.integrative_children_options"
)
const config: TableConfig<ProjectInstitution, MappedProjectInstitution> = {
  columnDefs: [
    {
      key: "n",
      header: i18n.global.t("labels.id"),
    },
    {
      key: "name",
      header: i18n.global.t("labels.name"),
    },
    {
      key: "institutionNumber",
      header: i18n.global.t("admin.einrichtungen.felder.einrichtungsnummer.label"),
      active: false,
    },
    {
      key: "internalInstitutionNumber",
      header: i18n.global.t("admin.einrichtungen.felder.interne_einrichtungsnummer.label"),
      active: false,
    },
    {
      key: "type",
      format: (institution) =>
        institution.type && i18n.global.t(`institution_type.${institution.type}`),
      header: i18n.global.t("admin.einrichtungen.felder.einrichtungsart.label"),
      active: false,
    },
    {
      key: "commune",
      fields: ["commune.name"],
      format: (institution) => institution.commune?.name,
      header: i18n.global.t("labels.sitzgemeinde"),
      active: false,
      requiredPermission: "commune:list",
    },
    {
      key: "yearOfConstruction",
      header: i18n.global.t("admin.einrichtungen.felder.baujahr.label"),
      active: false,
    },
    {
      key: "operatingPermitYear",
      header: i18n.global.t("admin.einrichtungen.felder.betriebserlaubnis.label"),
      active: false,
    },
    {
      key: "address.street",
      header: i18n.global.t("labels.address.street"),
      active: false,
    },
    {
      key: "address.zipCode",
      header: i18n.global.t("labels.address.zip_code"),
      active: false,
    },
    {
      key: "address.city",
      header: i18n.global.t("labels.address.ort"),
      active: false,
    },
    {
      key: "address.district",
      format: (institution) =>
        institution.address?.district && i18n.global.t(`regions.${institution.address?.district}`),
      header: i18n.global.t("labels.address.district"),
      active: false,
    },
    {
      key: "address.country",
      format: (institution) =>
        institution.address?.country &&
        i18n.global.t(`countries_code.${institution.address?.country}`),
      header: i18n.global.t("labels.address.country"),
      active: false,
    },
  ],
}

export default config
