<script setup lang="ts">
import { onActivated, onDeactivated, onMounted, onUnmounted } from "vue"

defineProps({
  disableNavigationButtons: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(["previous-record", "next-record"])

function handleKeyDown(event: KeyboardEvent) {
  const keyActions = {
    F11: "previous-record",
    F12: "next-record",
  } as const

  if (event.key === "F11" || event.key === "F12") {
    const action = keyActions[event.key]
    event.preventDefault()
    emit(action)
  }
}

onMounted(() => {
  window.addEventListener("keydown", handleKeyDown)
})

onActivated(() => {
  window.addEventListener("keydown", handleKeyDown)
})

onDeactivated(() => {
  window.removeEventListener("keydown", handleKeyDown)
})

onUnmounted(() => {
  window.removeEventListener("keydown", handleKeyDown)
})
</script>

<template>
  <Button
    v-tooltip.top="$t('tooltips.previous_entry')"
    icon="pi pi-angle-left"
    text
    rounded
    class="mr-2"
    :disabled="disableNavigationButtons"
    @click="$emit('previous-record')"
  />
  <Button
    v-tooltip.bottom="$t('tooltips.next_entry')"
    icon="pi pi-angle-right"
    text
    rounded
    :disabled="disableNavigationButtons"
    @click="$emit('next-record')"
  />
</template>
