import dayjs, { type Dayjs } from "dayjs"
import isBetween from "dayjs/plugin/isBetween"
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault(dayjs.tz.guess())
dayjs.extend(isBetween)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)

export const ISO_DATE_REGEX = /^\d{4}-\d{2}-\d{2}$/
export const LOCALE_DATE_REGEX = /^\d{1,2}\.\d{1,2}\.\d{4}$/
export const TIME_REGEX = /^(?:(?:[01]?\d)|(?:2[0-3])):[0-5]\d$/
export const TIME_REGEX_OPTIONAL = /^(?:(?:(?:[01]?\d)|(?:2[0-3])):[0-5]\d)?$/
export const ISO_DATE_WITHOUT_YEAR_REGEX = /^-\d{2}-\d{2}$/
export const millisecondsPerDay = 1000 * 60 * 60 * 24

/**
 * Pads a number or a string with `"0"` until the string reaches the desired length.
 * @param val A number or any other character.
 * @param length The final length of the string. Defaults to `2`.
 *        If the string is longer than this value, then the string will be returned as it is.
 * @returns The padded string.
 */
export function padZero(val: number | string, length = 2): string {
  return String(val).padStart(length, "0")
}

/**
 * Converts a date string in locale format ('DD.MM.YYYY') to 'YYYY-MM-DD' format.
 */
export const ISOToLocaleString = (input: string) => {
  if (!ISO_DATE_REGEX.test(input)) {
    return input
  }

  const [year, month, day] = input.split("-")
  return `${padZero(day)}.${padZero(month)}.${year}`
}

/**
 * Formats a `Dayjs` object in 'YYYY-MM-DD' format.
 */
export const formatISO = (date: Dayjs) => {
  return date.format().split("T")[0]
}

export const localeToISOString = (input: string): string => {
  if (!LOCALE_DATE_REGEX.test(input)) {
    return input
  }

  const [days, month, year] = input.split(".")
  return `${year}-${padZero(month)}-${padZero(days)}`
}
/**
 * Tests if the given string is a valid time representation of the form hh:mm
 */
export const isValidTimeString = (value: string | null | undefined) => {
  return value ? !!TIME_REGEX.test(value) : false
}

/**
 * Parses a time string into a `Dayjs` object, if the time does not belong to a concrete date.
 */
export const parseTime = (str: string) => {
  const date = dayjs(0)
  const [hours, minutes] = str.split(":").map(Number)
  return date.hour(hours).minute(minutes)
}

/**
 * A helper function to split the time to hour and minute
 */
export const splitTime = (timeStr: string): { hour: number; minute: number } => {
  const [hour, minute] = timeStr.split(":").map(Number)
  return { hour, minute }
}

/**
 *Validator to check if the time is valid
 */
export const isValidTime = (value: string): boolean => {
  const { hour, minute } = splitTime(value)
  return hour >= 0 && hour <= 23 && minute >= 0 && minute < 60
}

/**
 *Validator to check if endTime is after beginTime
 */
export const isTimeAfterTime = (
  beginTime: string | undefined,
  endTime: string | undefined
): boolean => {
  if (!beginTime || !endTime) {
    return true
  }
  const begin = splitTime(beginTime)
  const end = splitTime(endTime)

  const beginInMinutes = begin.hour * 60 + begin.minute
  const endInMinutes = end.hour * 60 + end.minute

  return endInMinutes > beginInMinutes
}

/**
 *Id Generator for mock data
 */
export function generateUniqueId() {
  return `id_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`
}

/**
 * Tests if a given value is a valid date object
 * @param date The value to test
 * @returns If the value is a valid date
 */
export function isDate(date: unknown): date is Date {
  return date instanceof Date && !isInvalid(date)
}

/**
 * Tests if a date is invalid.
 * @param date The date to test
 * @returns `true` if the date is invalid, `false` otherwise
 */
export function isInvalid(date: Date): boolean {
  return date.getTime() == null || Number.isNaN(date.getTime())
}

/**
 * Tests if a native date is valid.
 * @param dateString The date to test (e.g `1994-11-13` | `2000-02-24`)
 * @returns true if the date is valid
 */
export function isValidNativeDate(dateString: unknown): boolean {
  if (dateString === null || dateString === undefined) return false
  if (typeof dateString !== "string") return false
  if (dateString.trim() === "") return false
  const regex = /^\d{4}-\d{2}-\d{2}$/
  return regex.test(dateString) && isDate(new Date(dateString))
}

/**
 * Tries to convert a string to a date object.
 * If the input is not in yyyy-MM-dd format, the input is returned as it is.
 * If the input is an invalid date, an empty string will be returned.
 * @param input The date string
 * @returns The string or date object
 */
export function stringToDate(input: string): Date | string {
  if (input === "0000-00-00") {
    return ""
  }

  if (ISO_DATE_WITHOUT_YEAR_REGEX.test(input)) {
    return input.split("-").reverse().join(".")
  }

  if (!ISO_DATE_REGEX.test(input)) {
    return input
  }

  const date = new Date(input)
  return isDate(date) ? date : ""
}

export function isDateAfter(dateToCheck: string, referenceDate: string): boolean {
  if (!dateToCheck || !referenceDate) {
    return true
  }

  const dtCheck = new Date(dateToCheck)
  const dtRef = new Date(referenceDate)

  return dtCheck > dtRef
}
export function isDateBefore(dateToCheck: string, referenceDate: string): boolean {
  if (!dateToCheck || !referenceDate) {
    return true
  }
  const dtCheck = new Date(dateToCheck)
  const dtRef = new Date(referenceDate)
  return dtCheck < dtRef
}
export function isSameDate(dateToCheck: string, referenceDate: string): boolean {
  return dateToCheck === referenceDate
}

/**
 * Tests if a given date string is empty or `"0000-00-00"`
 * @param str The string to test
 * @returns If the string is empty
 */
export function isEmptyDate(str?: string | null) {
  return !str || str.trim() === "" || str === "0000-00-00"
}

/**
 * Converts a date string in yyyy-MM-dd format to locale format.
 */
export function toLocaleDateString(str = ""): string {
  if (isEmptyDate(str)) {
    return ""
  }
  return str.split("-").reverse().join(".")
}

export function toISODateString(date: Date | string | undefined | null) {
  if (!date) return null
  return dayjs(new Date(date)).format("YYYY-MM-DD")
}

export function toISOTimeString(date: Date | string = new Date()) {
  return new Date(date).toISOString().split("T")[1].slice(0, 5)
}

export function createISODateTimeFromString(
  dateStr: string | null,
  timeStr: string | null
): string {
  let year: number,
    month: number,
    day: number,
    hour = 0,
    minute = 0

  if (dateStr) {
    ;[year, month, day] = dateStr.split("-").map(Number)
  } else {
    // Set to "0000-00-00" equivalent
    year = 0
    month = 0
    day = 0
  }

  if (timeStr) {
    ;[hour, minute] = timeStr.split(":").map(Number)
  }

  // Handling the "0000-00-00" case to avoid invalid date
  if (year === 0 && month === 0 && day === 0) {
    return (
      "0000-00-00T" + `${hour.toString().padStart(2, "0")}:${minute.toString().padStart(2, "0")}`
    )
  }

  const date = new Date(Date.UTC(year, month - 1, day, hour, minute))
  return date.toISOString()
}
export function IsInvalidDate(dateTime: Date) {
  return isNaN(dateTime.getTime())
}
export function extractDateFromISO8601(isoString: string) {
  const dateTime = new Date(isoString)
  if (IsInvalidDate(dateTime)) {
    return ""
  }

  return toISODateString(dateTime)
}

export function extractTimeFromDate(date: Date) {
  return dayjs(date).format("HH:mm")
}

export function convertUTCDateTimeToLocalTime(
  utcDateTime: Date | string,
  timeZone?: string
): string {
  const parsedDateTime =
    typeof utcDateTime === "string" ? dayjs.utc(new Date(utcDateTime)) : dayjs.utc(utcDateTime)

  if (!parsedDateTime.isValid()) {
    return ""
  }
  if (!extractTimeFromISO8601(parsedDateTime.toISOString())) {
    return ""
  }
  try {
    const localDateTime = timeZone ? parsedDateTime.tz(timeZone) : parsedDateTime.local()
    const localTime = localDateTime.format("HH:mm")

    return localTime
  } catch {
    return ""
  }
}
export function extractTimeFromISO8601(isoString: string) {
  const dateTime = new Date(isoString)
  if (IsInvalidDate(dateTime)) {
    return ""
  }
  const time = toISOTimeString(dateTime)

  if (time === "00:00") {
    return ""
  }
  return time
}

export function formatDate(isoString: string | null) {
  if (!isoString) return ""
  return dayjs(isoString).format("DD.MM.YYYY")
}

export function formatToRfc3339(date: Date | string, time = "", tz = "") {
  const parsedDate = typeof date === "string" ? dayjs(date).utc() : dayjs(date)

  if (time && (!parsedDate.isValid() || !TIME_REGEX.test(time))) {
    return ""
  }

  const datePart = parsedDate.format("YYYY-MM-DD")

  const parsedDateTime = time
    ? dayjs.tz(`${datePart} ${time}`, tz).utc()
    : dayjs.tz(`${datePart}`, "UTC").utc()

  if (!parsedDateTime.isValid()) {
    return ""
  }

  const rfc3339DateTime = parsedDateTime.toISOString()
  return rfc3339DateTime
}
// Check if the first date is the same or after the second date
export function isAfterOrSame(date1: Date, date2: Date) {
  const day1 = dayjs(date1)
  const day2 = dayjs(date2)

  return day1.isSame(day2) || day1.isAfter(day2)
}
export function isDateOneYearAfterToday(date: Date) {
  const today = dayjs()
  const maxDate = today.add(1, "year")

  const dateToCheck = dayjs(date)

  return dateToCheck.isSame(today, "day") || dateToCheck.isBefore(maxDate)
}

export function calculateDateFromToday(years: number) {
  const today = new Date()
  const calculatedDate = new Date(today.getFullYear() + years, today.getMonth(), today.getDate())
  return calculatedDate
}

/**
 * Returns the first date of the same month and year as the provided date.
 *
 * @param {Date} date - The input date from which the first date of the month is derived.
 * @returns {Date} The first date of the month (same year and month as the input date).
 */
export function getFirstDateOfMonth(date: Date, asDate = false): Date | string | null {
  // Create a new date object with the same year and month, but set the date to 1
  const firstDate = new Date(date.getFullYear(), date.getMonth(), 1)
  return asDate ? firstDate : toISODateString(firstDate)
}

/**
 * Returns the last date of the same month and year as the provided date.
 *
 * @param {Date} date - The input date from which the last date of the month is derived.
 * @returns {Date} The last date of the month (same year and month as the input date).
 */
export function getLastDateOfMonth(date: Date, asDate = false): Date | string | null {
  // Create a new date object with the same year and the next month, set the date to 0
  // Setting the date to 0 will give us the last day of the previous month
  const lastDate = new Date(date.getFullYear(), date.getMonth() + 1, 0)
  return asDate ? lastDate : toISODateString(lastDate)
}

export function calculateDayDifference(birthDate: Date) {
  const today = new Date()
  // Set the birth date year to match today's year
  const adjustedBirthDate = new Date(birthDate)
  adjustedBirthDate.setFullYear(today.getFullYear())

  // Calculate the difference in days
  const difference = Math.floor(
    (today.getTime() - adjustedBirthDate.getTime()) / millisecondsPerDay
  )

  return difference !== 0 ? -difference : difference
}

export function calculateAge(birthDate: string) {
  return birthDate ? Math.floor(dayjs().diff(dayjs(birthDate), "year", true)) : -1
}

export function maybeParseDate(input: string | Date | null): Date | null {
  if (input === null) {
    return null
  }

  if (Object.prototype.toString.call(input) === "[object Date]") {
    if (isInvalid(input as Date)) return null

    return input as Date
  }

  if (!ISO_DATE_REGEX.test(input as string)) {
    return input as Date
  }
  const date = dayjs(input)
  return date.isValid() ? date.toDate() : null
}

export function isDateInRange(from: string, to?: string): boolean {
  if (!from) {
    return false
  }
  const today = dayjs().startOf("day")
  const fromDate = dayjs(from).startOf("day")

  if (!to) {
    return fromDate.isValid() && today.isSame(fromDate)
  }

  const toDate = dayjs(to).startOf("day")

  return today.isBetween(fromDate, toDate, "day", "[]")
}

export function sortByDate(dateA: string | Date, dateB: string | Date): number {
  return new Date(dateA).getTime() - new Date(dateB).getTime()
}
//TODO: Write the tests
export function hasOverlapOrInside(
  from: string,
  to: string | null,
  yearStart: Dayjs,
  yearEnd: Dayjs
): boolean {
  const fromDate = dayjs(from)
  const toDate = to ? dayjs(to) : dayjs("9999-12-31")

  return fromDate.isSameOrBefore(yearEnd) && toDate.isSameOrAfter(yearStart)
}
export function isTodayWithinRange(from: string, to: string | null) {
  const todaysDate = dayjs().startOf("day")
  const lastDayOfCurrentYear = dayjs().endOf("year").format("YYYY-MM-DD")

  const fromDate = dayjs(from).startOf("day")
  const toDate = to ? dayjs(to).startOf("day") : dayjs(lastDayOfCurrentYear)
  return todaysDate.isBetween(fromDate, toDate, "day", "[]")
}
export const isCurrentDateRange = (from: string, to?: string) => {
  const today = dayjs()
  const validFrom = dayjs(from).isSameOrBefore(today, "day")
  if (!to) {
    return validFrom
  }
  const validTo = dayjs(to).isSameOrAfter(today, "day")
  return validFrom && validTo
}
