import { type RouteRecordRaw } from "vue-router"

import { type Permission } from "@/types"

const routes: Array<RouteRecordRaw> = [
  {
    path: "/beitragsarten",
    name: "FeeTypePage",
    component: () => import("./feetypes/FeeTypePage.vue"),
    meta: {
      requiredRoles: <Permission[]>["fee_type:list", "fee_type:unlock"],
    },
  },
  {
    path: "/beitragsart/erstellen",
    name: "FeeTypeViewCreate",
    component: () => import("./feetypes/FeeTypeView.vue"),
    meta: {
      requiredRoles: <Permission[]>["fee_type:list", "fee_type:unlock"],
    },
    children: [
      {
        path: "",
        name: "FeeTypeFormCreate",
        component: () => import("./feetypes/FeeTypeForm.vue"),
      },
    ],
  },
  {
    path: "/beitragsart/:id",
    name: "FeeTypeViewEdit",
    component: () => import("./feetypes/FeeTypeView.vue"),
    meta: {
      requiredRoles: <Permission[]>["fee_type:list", "fee_type:unlock"],
    },
    children: [
      {
        path: "",
        name: "FeeTypeFormEdit",
        component: () => import("./feetypes/FeeTypeForm.vue"),
      },
    ],
  },

  {
    path: "/beitragsschemen",
    name: "AccountingGroupPage",
    component: () => import("./accountingGroup/AccountingGroupPage.vue"),
    meta: {
      requiredRoles: <Permission[]>["fee_type:list", "fee_type:unlock"],
    },
  },

  {
    path: "/beitragsschema/:id",
    name: "AccountingGroupViewEdit",
    component: () => import("./accountingGroup/AccountingGroupView.vue"),
    meta: {
      requiredRoles: <Permission[]>["fee_type:view", "fee_type:unlock"],
    },
    children: [
      {
        path: "",
        name: "AccountingGroupFormEdit",
        component: () => import("./accountingGroup/AccountingGroupForm.vue"),
      },
    ],
  },
  {
    path: "/beitragsschema/erstellen",
    name: "AccountingGroupViewCreate",
    component: () => import("./accountingGroup/AccountingGroupView.vue"),
    meta: { requiredRoles: <Permission[]>["fee_type:create", "fee_type:unlock"] },
    children: [
      {
        path: "",
        name: "AccountingGroupFormCreate",
        component: () => import("./accountingGroup/AccountingGroupForm.vue"),
      },
    ],
  },
  {
    path: "/beitragsvorlagen",
    name: "FeeDraftPage",
    component: () => import("./feeDrafts/FeeDraftPage.vue"),
    meta: {},
  },
  {
    path: "/beitragsvorlagen/:id",
    name: "FeeDraftViewEdit",
    component: () => import("./feeDrafts/FeeDraftView.vue"),
    meta: {},
    children: [
      {
        path: "",
        name: "FeeDraftFormEdit",
        component: () => import("./feeDrafts/FeeDraftForm.vue"),
      },
    ],
  },
  {
    path: "/beitragsvorlagen/erstellen",
    name: "FeeDraftViewCreate",
    component: () => import("./feeDrafts/FeeDraftView.vue"),
    meta: {},
    children: [
      {
        path: "",
        name: "FeeDraftFormCreate",
        component: () => import("./feeDrafts/FeeDraftForm.vue"),
      },
    ],
  },
]

export default routes
