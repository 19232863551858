import { createRouter, createWebHistory, type RouteRecordRaw } from "vue-router"

import adminRoutes from "./views/admin/routes"
import childrenRoutes from "./views/children/routes"
import exportRoutes from "./views/export/routes"
import feeRoutes from "./views/fees/routes"
import analysisRoutes from "./views/home/routes"
import employeesRoutes from "./views/personal/routes"
import stammdatenRoutes from "./views/stammdaten/routes"

import { useSessionStore } from "@/store/session"
import { type Permission } from "@/types"

export const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: () => import("./views/base/AppLayout.vue"),
    children: [
      {
        path: "/",
        name: "dashboard",
        meta: {
          requiresInstitutionContext: false,
        },
        component: () => import("./views/DashBoard.vue"),
        children: [
          {
            path: "erstellen",
            name: "DashboardAppointmentDialogCreate",
            component: () => import("./views/personal/appointments/AppointmentDialog.vue"),
          },
          {
            path: "dashboard/termine/:appointmentId",
            name: "DashboardAppointmentDialogEdit",
            component: () => import("./views/personal/appointments/AppointmentDialog.vue"),
          },
        ],
      },
      {
        path: "/:pathMatch(.*)*",
        name: "PageNotFound",
        component: () => import("./views/error/NotFound.vue"),
      },
      ...childrenRoutes,
      ...employeesRoutes,
      ...stammdatenRoutes,
      ...adminRoutes,
      ...feeRoutes,
      ...analysisRoutes,
      ...exportRoutes,
    ],
  },
  {
    path: "/report/:resource?",
    name: "report",
    component: () => import("./views/PsPdf.vue"),
    props: true,
    meta: {
      requiresAuth: true,
      requiresInstitutionContext: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("./views/LoginPage.vue"),
    meta: {
      requiresAuth: false,
      requiresInstitutionContext: false,
    },
  },
  {
    path: "/error",
    name: "error",
    component: () => import("./views/error/ErrorPage.vue"),
  },
]

export const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach(async (to) => {
  const sessionStore = useSessionStore()

  if (to.meta?.requiresAuth !== false) {
    if (!sessionStore.loggedIn) {
      console.warn(
        `User is not logged in but it is required for the requested page "${to.fullPath}"!`
      )
      await router.replace({
        name: "login",
        query: {
          target: to.fullPath,
        },
      })
      return
    }
  }

  if (to.meta?.requiresInstitutionContext !== false && !sessionStore.contextInstitutionId) {
    await router.replace({
      name: "dashboard",
    })
    return
  }

  if (
    (((to.meta?.requiredRoles as Permission[]) || []).length > 0 &&
      !sessionStore.hasRoles(<Permission[]>to.meta?.requiredRoles)) ||
    (to.meta?.requiresAdmin && !sessionStore.isUserAdmin)
  ) {
    console.warn(`User does not possess the required role to reach "${to.fullPath}"`)
    await router.replace({ name: "error" })
    return
  }
})
