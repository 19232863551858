import Keycloak from "keycloak-js"
import { defineStore } from "pinia"
import { ref, computed, nextTick, watch } from "vue"

import { type Config, useConfigStore } from "./config"
import { useCustomerStore } from "./customer"
import { useInstitutionStore } from "./institution"
import { useOrganizationStore } from "./organization"

import type { KeycloakConfig } from "keycloak-js"

import AvatarImg from "@/assets/images/avatar-5.png"
import { type SearchType } from "@/graphql/search_types"
import {
  type CustomerFilter,
  type OrganizationFilter,
  type InstitutionFilter,
  type StrictInstitutionId,
  type ResourceType,
} from "@/graphql/types"
import { type Permission } from "@/types"
import { splitLongId } from "@/utils/entity"

type SessionData = {
  username: string | undefined
  firstname: string | undefined
  lastname: string | undefined
  groups: string[]
  roles: string[]
}

let keycloak: Keycloak | null = null

async function createKeycloak(config: Config) {
  const keycloakConfig: KeycloakConfig = {
    url: config.keycloakUrl,
    realm: config.keycloakRealm,
    clientId: config.keycloakClientId,
  }
  keycloak = new Keycloak(keycloakConfig)
}

function clearSession() {
  keycloak?.clearToken()
}

function getTimeSkew(): number | undefined {
  if (keycloak) return keycloak.timeSkew
}

function getTokenExpDate(): Date | null {
  if (keycloak?.tokenParsed?.exp) {
    return new Date(keycloak.tokenParsed.exp * 1000)
  }
  return null
}

function getRefreshTokenExpDate(): Date | null {
  if (keycloak?.refreshTokenParsed?.exp) {
    const expTime = keycloak.refreshTokenParsed.exp * 1000
    const timeSkew = (getTimeSkew() ?? 0) * 1000
    return new Date(expTime + timeSkew)
  }
  return null
}

const authTime = computed<Date | null>(() => {
  if (keycloak?.tokenParsed?.auth_time) {
    return new Date(keycloak.tokenParsed.auth_time * 1000)
  }
  return null
})

const tokenExp = computed<number | undefined>(() => {
  if (keycloak?.tokenParsed?.exp)
    return Math.trunc(
      (new Date(keycloak.tokenParsed.exp * 1000).getTime() - new Date().getTime()) / 1000
    )
  return undefined
})

const MIN_VALIDITY = 70

async function updateToken(minValidity = MIN_VALIDITY): Promise<void> {
  await keycloak
    ?.updateToken?.(minValidity)
    .then(function (refreshed) {
      if (refreshed) {
        console.warn("Token was successfully refreshed")
      } else {
        console.debug("Token is still valid")
      }
    })
    .catch(function () {
      console.error("Failed to refresh the token, or the session has expired")
      clearSession()
    })
}

function isTokenExpired(minValidity = 70): boolean | undefined {
  return keycloak?.isTokenExpired?.(minValidity)
}

export const useSessionStore = defineStore("session", () => {
  const customerStore = useCustomerStore()
  const organizationStore = useOrganizationStore()
  const institutionStore = useInstitutionStore()
  const configStore = useConfigStore()

  const sessionData = ref<SessionData>()
  const userCustomerId = ref<string>()
  const userOrganizationId = ref<string>()
  const userOrganizationUnitId = ref<string>()
  const userInstitutionId = ref<string>()

  const loggedIn = ref(false)
  const EXPIRY_NOTICE_PERIOD = 60 * 5
  const currentUserDisplayName = computed(
    () =>
      displayName(sessionData.value?.firstname, sessionData.value?.lastname) ||
      sessionData.value?.username ||
      "Gast" // TODO
  )
  const currentUserProfileImage = computed(() => AvatarImg) // TODO

  // if the logged in user is on a higher level than institution, these values become relevant
  const _selectedContextInstitutionId = ref<string>()
  const _selectedContextOrganizationId = ref<string>()
  const _selectedContextCustomerId = ref<string>()

  const selectedContextInstitutionId = computed({
    get: () => _selectedContextInstitutionId.value,
    set: (id) => {
      if (!id) {
        _selectedContextInstitutionId.value = undefined
        return
      }
      _selectedContextInstitutionId.value = id
    },
  })
  const selectedContextOrganizationId = computed({
    get: () => _selectedContextOrganizationId.value,
    set: (id) => {
      if (!id) {
        _selectedContextOrganizationId.value = undefined
        _selectedContextInstitutionId.value = undefined
        return
      }
      _selectedContextOrganizationId.value = id
    },
  })
  const selectedContextCustomerId = computed({
    get: () => _selectedContextCustomerId.value,
    set: (id) => {
      if (!id) {
        _selectedContextCustomerId.value = undefined
        _selectedContextOrganizationId.value = undefined
        _selectedContextInstitutionId.value = undefined
        return
      }

      _selectedContextCustomerId.value = id

      //COmmenting this lines fix the issue
      // Are they being used?

      // _selectedContextOrganizationId.value = undefined
      // _selectedContextInstitutionId.value = undefined
    },
  })
  watch(_selectedContextInstitutionId, (id) =>
    localStorage.setItem("selectedContextInstitutionId", id || "")
  )
  watch(_selectedContextOrganizationId, (id) =>
    localStorage.setItem("selectedContextOrganizationId", id || "")
  )
  watch(_selectedContextCustomerId, (id) =>
    localStorage.setItem("selectedContextCustomerId", id || "")
  )

  const contextInstitutionId = computed(
    () => userInstitutionId.value || _selectedContextInstitutionId.value
  )
  const contextOrganizationId = computed(
    () => userOrganizationId.value || _selectedContextOrganizationId.value
  )
  const contextCustomerId = computed(() => userCustomerId.value || _selectedContextCustomerId.value)

  const customerFilter = computed<CustomerFilter | {}>(() =>
    isUserAdmin.value
      ? {
          customer: contextCustomerId.value,
        }
      : {}
  )

  const organizationFilter = computed<OrganizationFilter | {}>(() =>
    isUserCustomerOrHigher.value
      ? {
          customer: contextCustomerId.value,
          organization: contextOrganizationId.value,
        }
      : {}
  )

  const institutionFilter = computed<InstitutionFilter>(() => ({
    customer: contextCustomerId.value,
    organization: contextOrganizationId.value,
    institution: contextInstitutionId.value,
  }))

  // NOTE: Both institutionFilter and strictInstitutionId are basically the same, just with different keys.
  // Streamlining efforts in the backend might help minimizing these to one unified schema.

  const strictInstitutionId = computed<StrictInstitutionId>(() => ({
    cid: contextCustomerId.value,
    oid: contextOrganizationId.value,
    iid: contextInstitutionId.value,
  }))

  const isUserAdmin = computed(
    () => !!sessionData.value?.roles.find((role) => role.startsWith("administration"))
  )
  const isUserCustomer = computed(
    () => !!userCustomerId.value && !userOrganizationId.value && !userOrganizationUnitId.value
  )
  const isUserOrganization = computed(
    () => !!userOrganizationId.value && !userOrganizationUnitId.value && !userInstitutionId.value
  )
  const isUserOrganizationUnit = computed(() => !!userOrganizationUnitId.value)
  const isUserInstitution = computed(() => !!userInstitutionId.value)

  const isUserCustomerOrHigher = computed(() => isUserAdmin.value || isUserCustomer.value)
  const isUserOrganizationOrHigher = computed(
    () => isUserCustomerOrHigher.value || isUserOrganization.value
  )
  const isUserOrganizationUnitOrHigher = computed(
    () => isUserCustomerOrHigher.value || isUserOrganizationUnit.value
  )
  const isUserOrganizationOrUnitOrHigher = computed(
    () => isUserCustomerOrHigher.value || isUserOrganization.value || isUserOrganizationUnit.value
  )
  const isUserInstitutionOrHigher = computed(
    () => isUserOrganizationOrUnitOrHigher.value || isUserInstitution.value
  )
  watch(
    () => customerStore.contextCustomerList,
    (customers) => {
      if (
        selectedContextCustomerId.value &&
        !customers?.find((c) => splitLongId(c.id)[3] === selectedContextCustomerId.value)
      ) {
        selectedContextCustomerId.value = undefined
      }
    }
  )

  watch(
    () => organizationStore.contextOrganizationList,
    (organizations) => {
      organizations =
        organizations?.filter((o) => splitLongId(o.id)[0] === contextCustomerId.value) || []
      if (
        selectedContextOrganizationId.value &&
        !organizations.find((o) => splitLongId(o.id)[3] === selectedContextOrganizationId.value) &&
        !organizationStore.contextOrganizationsLoading
      ) {
        selectedContextOrganizationId.value = undefined
      }
    }
  )

  watch(
    () => institutionStore.contextInstitutionList,
    (institutions) => {
      institutions =
        institutions?.filter((i) => splitLongId(i.id)[1] === contextOrganizationId.value) || []
      if (
        selectedContextInstitutionId.value &&
        !institutions.find((i) => splitLongId(i.id)[3] === selectedContextInstitutionId.value)
      ) {
        selectedContextInstitutionId.value = undefined
      }
    }
  )

  const accessLevelViewPermissions = computed(() => ({
    includeCustomer: hasRoles(<Permission[]>["customer:view"]),
    includeOrganization: hasRoles(<Permission[]>["organization:view"]),
    includeInstitution: hasRoles(<Permission[]>["institution:view"]),
  }))
  const accessLevelListPermissions = computed(() => ({
    includeCustomer: hasRoles(<Permission[]>["customer:list"]),
    includeOrganization: hasRoles(<Permission[]>["organization:list"]),
    includeInstitution: hasRoles(<Permission[]>["institution:list"]),
  }))

  async function login() {
    await createKeycloak(await configStore.load())

    const authenticated = await keycloak?.init({
      onLoad: "login-required",
      enableLogging: true,
      timeSkew: 10,
      checkLoginIframe: false,
    })

    if (authenticated) {
      return await loadUserData()
    } else {
      console.error("Login failed!")
      return false
    }
  }

  async function loadUserData() {
    const tokenParsed = await getTokenParsed()
    if (tokenParsed) {
      loggedIn.value = true
      sessionData.value = {
        username: tokenParsed.preferred_username,
        firstname: tokenParsed.given_name,
        lastname: tokenParsed.family_name,
        groups: tokenParsed.groups || [],
        roles: tokenParsed.realm_access?.roles || [],
      }

      {
        const role = sessionData.value?.roles.find((role) => role.startsWith("customer:access@"))
        if (role) {
          ;[userCustomerId.value] = splitLongId(role.replace("customer:access@", ""))
        }
      }
      {
        const role = sessionData.value?.roles.find((role) =>
          role.startsWith("organization:access@")
        )
        if (role) {
          ;[userCustomerId.value, userOrganizationId.value] = splitLongId(
            role.replace("organization:access@", "")
          )
        }
      }
      {
        const role = sessionData.value?.roles.find((role) =>
          role.startsWith("organization_unit:access@")
        )
        if (role) {
          let organizationOrUnitId, unitIdOrEmpty
          ;[userCustomerId.value, organizationOrUnitId, unitIdOrEmpty] = splitLongId(
            role.replace("organization_unit:access@", "")
          )
          if (unitIdOrEmpty) {
            userOrganizationId.value = organizationOrUnitId
            userOrganizationUnitId.value = unitIdOrEmpty
          } else userOrganizationUnitId.value = organizationOrUnitId
        }
      }
      {
        const role = sessionData.value?.roles.find((role) => role.startsWith("institution:access@"))
        if (role) {
          ;[userCustomerId.value, userOrganizationId.value, userInstitutionId.value] = splitLongId(
            role.replace("institution:access@", "")
          )
        }
      }

      if (localStorage.getItem("selectedContextCustomerId")) {
        _selectedContextCustomerId.value =
          localStorage.getItem("selectedContextCustomerId") || undefined
      }
      if (localStorage.getItem("selectedContextOrganizationId")) {
        _selectedContextOrganizationId.value =
          localStorage.getItem("selectedContextOrganizationId") || undefined
      }
      if (localStorage.getItem("selectedContextInstitutionId")) {
        _selectedContextInstitutionId.value =
          localStorage.getItem("selectedContextInstitutionId") || undefined
      }
      await nextTick()
      return true
    } else {
      console.error("Error while loading user data!")
      return false
    }
  }

  async function getTokenParsed() {
    if (!keycloak) {
      await createKeycloak(await configStore.load())
    }
    return keycloak?.tokenParsed
  }

  function logout() {
    keycloak
      ?.logout({
        redirectUri: `${window.location.protocol}//${window.location.host}/#/login`,
      })
      .then(() => {
        _selectedContextInstitutionId.value = undefined
        _selectedContextOrganizationId.value = undefined
        _selectedContextCustomerId.value = undefined
      })
  }

  async function getToken(checkSso = true) {
    if (!keycloak) {
      await createKeycloak(await configStore.load())
    }

    if (checkSso && !keycloak?.token) {
      await keycloak?.init({ onLoad: "check-sso" })
    }

    return keycloak?.token
  }

  function displayName(firstname?: string, lastname?: string, username = "") {
    return `${firstname || ""} ${lastname || ""}`.trim() || username
  }

  /**
   * Checks if the user has the provided roles.
   *
   * <p>The default check requires the user to have all roles provided.</p>
   * <p>If the check should determine
   * whether the user has <strong>any</strong> of the provided roles,
   * the flag <code>any</code> must be set to <code>true</code>.</p>
   * @param roles to check the user for.
   * @param any to check if any one of the roles is assigned to the user.
   */

  function hasRoles(roles: Permission[], any = false) {
    if (!sessionData.value) {
      return false
    }

    // TODO: split function further up - e.g. with "none" permissions, admin check does not work semantically correct any more
    if (isUserAdmin.value) {
      return true
    }

    if (any) {
      // Check if the user has any role
      return roles.some((role) => sessionData.value?.roles.includes(role))
    }

    // Check if the user has all roles
    return roles.every((role) => sessionData.value?.roles.includes(role))
  }

  function hasUnlockRight(type: ResourceType | SearchType) {
    return sessionData.value?.roles.includes(`${type}:unlock`)
  }

  /**
   * Checks if the user has the provided groups.
   *
   * <p>The default check requires the user to have all groups provided.</p>
   * <p>If the check should determine
   * whether the user has <strong>any</strong> of the provided groups,
   * the flag <code>any</code> must be set to <code>true</code>.</p>
   * @param groups to check the user for.
   * @param any to check if the user is in any one of the groups.
   */
  function isInGroups(groups: string[], any = false) {
    if (!sessionData.value) {
      return false
    }

    if (any) {
      // Check if the user is in any group
      return groups.some((group) => sessionData.value?.groups.includes(group))
    }

    // Check if the user is in all groups
    return groups.every((group) => sessionData.value?.groups.includes(group))
  }

  function generateLongEntityId(objectId: string) {
    return `${contextCustomerId.value}${contextOrganizationId.value}${contextInstitutionId.value}${objectId}`
  }

  return {
    loggedIn,
    sessionData,
    currentUserDisplayName,
    currentUserProfileImage,
    login,
    logout,
    getToken,
    hasRoles,
    hasUnlockRight,
    isInGroups,
    userCustomerId,
    userOrganizationId,
    userOrganizationUnitId,
    userInstitutionId,
    selectedContextInstitutionId,
    selectedContextOrganizationId,
    selectedContextCustomerId,
    contextInstitutionId,
    contextOrganizationId,
    contextCustomerId,
    customerFilter,
    organizationFilter,
    institutionFilter,
    strictInstitutionId,
    isUserAdmin,
    isUserCustomer,
    isUserOrganization,
    isUserOrganizationUnit,
    isUserCustomerOrHigher,
    isUserOrganizationOrHigher,
    isUserOrganizationUnitOrHigher,
    isUserOrganizationOrUnitOrHigher,
    isUserInstitutionOrHigher,
    isUserInstitution,
    accessLevelViewPermissions,
    generateLongEntityId,
    accessLevelListPermissions,

    authTime,
    tokenExp,
    EXPIRY_NOTICE_PERIOD,

    getTokenExpDate,
    getRefreshTokenExpDate,
    updateToken,
    isTokenExpired,
    clearSession,
  }
})
