import type { Permission } from "@/types"
import type { RouteRecordRaw } from "vue-router"

import { FunctionalRole } from "@/graphql/types"

export const requiredKibigRoles = <Permission[]>[
  FunctionalRole.BaykibigExport,
  "employment:list",
  "employment:view",
  "employee:list",
  "employee:view",
  "child:list",
  "child:view",
  "commune:view",
  "contract:view",
  "booking:view",
]

const exportRoutes: Array<RouteRecordRaw> = [
  {
    path: "/export/kibigweb",
    name: "KibigWebExport",
    meta: {
      requiresInstitutionContext: true,
      requiredRoles: requiredKibigRoles,
    },
    component: () => import("./KibigWebExport.vue"),
  },
]

export default exportRoutes
