import { i18n as internationalization } from "@/i18n"

export function useDropdown() {}

export const getOptions = (
  enumObject: Record<string, string>,
  translationPath: string,
  ignore: string[] = []
) => {
  const values = Object.values(enumObject)

  const filteredValues = ignore.length ? values.filter((key) => !ignore.includes(key)) : values

  return filteredValues.map((key) => ({
    name: internationalization.global.t(
      `${translationPath}${key.includes(".") ? `[${key}]` : `.${key}`}`,
      key
    ),
    code: key,
  }))
}
