import { ApolloClient, type ApolloLink, InMemoryCache } from "@apollo/client/core"
import { setContext } from "@apollo/client/link/context"
import createUploadLink from "apollo-upload-client/createUploadLink.mjs"
import fetch from "cross-fetch"

import searchPossibleTypes from "./graphql/possibleSearchTypes_generated.json"
import possibleTypes from "./graphql/possibleTypes_generated.json"
import { useSessionStore } from "./store/session"

export enum ApolloFetchPolicy {
  CacheFirst = "cache-first",
  CacheAndNetwork = "cache-and-network",
  NetworkOnly = "network-only",
}

const httpLink = createUploadLink({
  uri: import.meta.env.DEV
    ? "http://localhost:8080/api/graphql"
    : import.meta.env.BASE_URL + "/api/graphql",
  fetch: ((input: RequestInfo | URL, init?: RequestInit) => fetch(input, init)) as never,
})

const authLink = setContext(async (_, { headers }) => {
  const sessionStore = useSessionStore()
  const token = await sessionStore.getToken(false)
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  }
})

export const apolloClient = new ApolloClient({
  devtools: {
    enabled: import.meta.env.DEV,
    name: "adebisKita Main",
  },
  link: authLink.concat(httpLink as unknown as ApolloLink),
  cache: new InMemoryCache({
    possibleTypes,
    typePolicies: {
      Address: {
        merge: true,
      },
      AccountingGroups: {
        merge: true,
      },
      Birth: {
        merge: true,
      },
      Category: {
        merge: true,
      },
      Child: {
        merge: true,
      },
      Booking: {
        fields: {
          typeObject: {
            merge(_, incoming) {
              return incoming
            },
          },
          timeFactor: {
            merge(_, incoming) {
              return incoming
            },
          },
          times: {
            merge(_, incoming) {
              return incoming
            },
          },
          schoolData: {
            merge(_, incoming) {
              return incoming
            },
          },
        },
      },
      Commune: {
        merge: true,
      },
      Contact: {
        merge: true,
      },
      ContactPerson: {
        merge: true,
      },
      Customer: {
        merge: true,
      },
      Employee: {
        merge: true,
      },
      EmployeeGroup: {
        merge: true,
      },
      Employment: {
        merge: true,
      },
      FoodType: {
        merge: true,
      },
      FeeType: {
        merge: true,
        fields: {
          contributionRates: {
            merge(_, incoming = []) {
              return incoming
            },
          },
        },
      },
      Group: {
        merge: true,
        fields: {
          managements: {
            merge(_, incoming = []) {
              return incoming
            },
          },
        },
      },
      Institution: {
        merge: true,
      },
      Movement: {
        merge: true,
      },
      Organization: {
        merge: true,
      },
      OrganizationUnit: {
        merge: true,
      },
      Person: {
        merge: true,
      },
      Qualification: {
        merge: true,
      },
      Registration: {
        merge: true,
      },
      Role: {
        fields: {
          permissions: {
            merge(_, incoming) {
              return incoming
            },
          },
        },
      },
      School: {
        merge: true,
      },
      SectionTime: {
        merge: true,
      },
      ServiceType: {
        merge: true,
      },
      SimpleAddress: {
        merge: true,
      },
      TimeRange: {
        merge: true,
      },
      Times: {
        merge: true,
      },
      Vaccination: {
        merge: true,
      },
      YouthWelfareOffice: {
        merge: true,
      },

      ProjectInstitution: {
        fields: {
          banks: {
            merge(_, incoming = []) {
              return incoming
            },
          },
        },
      },
      Appointment: {
        fields: {
          employees: {
            merge(_, incoming = []) {
              return incoming
            },
          },
        },
      },
    },
  }),
})

const searchHttpLink = createUploadLink({
  uri: import.meta.env.DEV
    ? "http://localhost:8080/api/search"
    : import.meta.env.BASE_URL + "/api/search",
  fetch: ((input: RequestInfo | URL, init?: RequestInit) => fetch(input, init)) as never,
})

export const searchApolloClient = new ApolloClient({
  devtools: {
    enabled: import.meta.env.DEV,
    name: "adebisKita Search",
  },
  link: authLink.concat(searchHttpLink as unknown as ApolloLink),
  cache: new InMemoryCache({
    possibleTypes: searchPossibleTypes,
  }),
})
