import dayjs, { type Dayjs } from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"

import { formatISO, localeToISOString, padZero } from "./date"

import { i18n } from "@/i18n"

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault(dayjs.tz.guess())
dayjs.extend(customParseFormat)

export enum YearMode {
  Calendar = "K",
  Kindergarten = "C",
}

export type Year = {
  start: Date
  end: Date
  mode: YearMode
}
export enum Month {
  JANUARY = 0,
  FEBRUARY,
  MARCH,
  APRIL,
  MAY,
  JUNE,
  JULY,
  AUGUST,
  SEPTEMBER,
  OCTOBER,
  NOVEMBER,
  DECEMBER,
}

export enum Weekday {
  Sunday = 0,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

export const WeekDayLocale = {
  [Weekday.Sunday]: i18n.global.t("weekdays.su"),
  [Weekday.Monday]: i18n.global.t("weekdays.mo"),
  [Weekday.Tuesday]: i18n.global.t("weekdays.tu"),
  [Weekday.Wednesday]: i18n.global.t("weekdays.we"),
  [Weekday.Thursday]: i18n.global.t("weekdays.th"),
  [Weekday.Friday]: i18n.global.t("weekdays.fr"),
  [Weekday.Saturday]: i18n.global.t("weekdays.sa"),
}
export const HolidayLocale = {
  "st-stephens-day": i18n.global.t("holidays.st-stephens-day"),
  "christmas-day": i18n.global.t("holidays.christmas-day"),
  "all-saints-day": i18n.global.t("holidays.all-saints-day"),
  "german-unity-day": i18n.global.t("holidays.german-unity-day"),
  "assumption-of-mary": i18n.global.t("holidays.assumption-of-mary"),
  "augsburger-peace-festival": i18n.global.t("holidays.augsburger-peace-festival"),
  "corpus-christi": i18n.global.t("holidays.corpus-christi"),
  "whit-monday": i18n.global.t("holidays.whit-monday"),
  "ascension-day": i18n.global.t("holidays.ascension-day"),
  "easter-monday": i18n.global.t("holidays.easter-monday"),
  "good-friday": i18n.global.t("holidays.good-friday"),
  epiphany: i18n.global.t("holidays.epiphany"),
  "new-years-day": i18n.global.t("holidays.new-years-day"),
  "labor-day": i18n.global.t("holidays.labor-day"),
}
export type HolidayKey = keyof typeof HolidayLocale

export type BaseDay = {
  date: string
  dayName: Weekday
}

export type BaseMonth = {
  month: string
  days: BaseDay[]
}

export type BaseRange = {
  from: string
  to: string
}

/**
 * Checks if a given year is a leap year.
 * @param year The year
 * @param yemodear The Year mode
 * @returns Year.
 */

export function createYear(year: number, mode: YearMode) {
  let start, end

  if (mode === YearMode.Kindergarten) {
    start = new Date(year, Month.SEPTEMBER, 1)
    end = endOfDay(new Date(year + 1, Month.AUGUST, 31, 23, 59))
  } else {
    start = new Date(year, Month.JANUARY, 1)
    end = endOfDay(new Date(year, Month.DECEMBER, 31))
  }

  return {
    start,
    end,
    mode,
  }
}

/**
 * Checks if a given year is a leap year.
 * @param year The year.
 * @returns Whether the year is a leap year.
 */
export function isLeapYear(year: number): boolean {
  if (!Number.isInteger(year)) {
    throw new Error("year must be an integer")
  }
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0
}

/**
 * Gets the number of days in the given year. In a leap year, that will be 366, otherwise 365.
 * @param year The year.
 * @returns The number of days.
 */
export function daysInYear(year: number): number {
  return isLeapYear(year) ? 366 : 365
}

/**
 * Provides an array with the number of days for the months in a given year.
 * The array is zero-index (January = 0, ..., December = 11).
 * @param year The year. Defaults to the today's year.
 * @returns The array.
 */
export function monthDays(year: number): number[] {
  return [31, isLeapYear(year) ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
}

/**
 * Checks if the given date is a weekend's day.
 * @param date The date.
 * @returns Whether the date is weekend.
 */
export function isWeekend(date: Dayjs): boolean {
  return date.day() === Weekday.Saturday || date.day() === Weekday.Sunday
}

/**
 * Calculates the age of a person at a given date.
 * @param birthday The date of birth given as a date object or a yyyy-MM-dd string
 * @param refdate Optional: Reference date, defaults to today's date
 * @returns The computed age
 */
export function calculateAge(birthday: Dayjs, refdate = dayjs()): number {
  const years = refdate.year() - birthday.year()

  if (
    birthday.month() > refdate.month() ||
    (birthday.month() == refdate.month() && birthday.date() > refdate.date())
  ) {
    return years - 1
  } else {
    return years
  }
}

/**
 * Checks if a person reaches a given age within the month of a reference date.
 * @param age The age that should be reached
 * @param birthday The birthday of the person
 * @param refdate The reference date
 * @returns Returns true, if the age is reached within the month.
 */
export function reachesAgeInMonth(age: number, birthday: Dayjs, refdate: Dayjs): boolean {
  const endOfLastMonth = refdate.startOf("month").subtract(1, "day")
  const ageBeforeMonth = calculateAge(birthday, endOfLastMonth)
  return ageBeforeMonth === age - 1 && birthday.month() === refdate.month()
}

/**
 * Returns the first day of the kindergarten year of the current date
 */
export function firstDayOfKindergartenYear(date = dayjs()): Dayjs {
  const year = date.utc().year()
  const refDate = dayjs.utc(`${year}-09-01`)
  return date.isBefore(refDate) ? refDate.subtract(1, "year") : refDate
}

/**
 * Returns the last day of the kindergarten year of the current date
 */
export function lastDayOfKindergartenYear(date = dayjs()): Dayjs {
  const year = date.utc().year()
  const refDate = dayjs.utc(`${year}-08-31`)
  return date.isAfter(refDate) ? refDate.add(1, "year") : refDate
}

/**
 * Calculates the date of easter for a given year
 * @param year The year
 * @returns The date of easter
 */
export function getEaster(year: number): Dayjs {
  const M = 24
  const N = 5
  const a = year % 4
  const b = year % 7
  const c = year % 19
  const d = (19 * c + M) % 30
  const e = (2 * a + 4 * b + 6 * d + N) % 7
  let day = 22 + d + e

  // Special cases adjustment
  if (d === 29 && e === 6) {
    day -= 7
  } else if (d === 28 && e === 6 && c > 10) {
    day -= 7
  }

  return dayjs.utc(new Date(Date.UTC(year, 2 /* March */, day)))
}

/**
 * Checks if the given date is a public holidays in Bavaria.
 * @param date The date to check.
 */
export function isGermanHoliday(date: Dayjs): boolean {
  const day = date.date()
  const month = date.month()
  const year = date.year()
  const easter = getEaster(year)

  return (
    (month === Month.JANUARY && day === 1) || // Neujahr
    (month === Month.JANUARY && day === 6) || // Hl. drei Könige
    (month === Month.MAY && day === 1) || // Tag der Arbeit
    (month === Month.AUGUST && day === 15) || // Mariä Himmelfahrt
    (month === Month.OCTOBER && day === 3) || // Tag d. deutschen Einheit
    (month === Month.NOVEMBER && day === 1) || // Allerheiligen
    (month === Month.DECEMBER && (day === 25 || day === 26)) || // 1. und 2. Weihnachtsfeiertag
    date.isSame(easter.subtract(46, "days"), "date") || // Aschermittwoch
    date.isSame(easter.subtract(2, "days"), "date") || // Karfreitag
    date.isSame(easter, "date") || // Ostern
    date.isSame(easter.add(1, "day"), "date") || // Ostermontag
    date.isSame(easter.add(39, "days"), "date") || // Christi Himmelfahrt
    date.isSame(easter.add(49, "days"), "date") || // Pfingstsonntag
    date.isSame(easter.add(50, "days"), "date") || // Pfingstmontag
    date.isSame(easter.add(60, "days"), "date") || // Fronleichnam
    date.isSame("2017-10-31")
  ) // Reformationstag 2017
}
/**
 * Returns the year of the given date, or today's year, if no date is specified.
 * @param date The date, defaults to today.
 * @returns The year.
 */
export function getYear(date = new Date()) {
  return dayjs(date).utc().year()
}

/**
 * Returns the month of the given date, or today's month, if no date is specified.
 * @param date The date, defaults to today.
 * @returns The month.
 */
export function getMonth(date = new Date()) {
  return dayjs(date).utc().month()
}

export function isKindergartenYear(year: Year): boolean {
  return year.mode === YearMode.Kindergarten
}
/**
 * Returns the kindergarten year of the given date, or the current kindergarten year
 * @param date [Optional] The date. If omitted, the current kindergarten year is returned
 * @returns A `Year` object
 */
export function getKindergartenYear(date = new Date()): Year {
  let year = date.getFullYear()
  if (date.getMonth() < Month.SEPTEMBER) year -= 1

  return createYear(year, YearMode.Kindergarten)
}

/**
 * Returns the calendar year of the given date, or the current calender year
 * @param date [Optional] The date. If omitted, the current calendar year is returned
 * @returns A `Year` object
 */
export function getCalendarYear(date = new Date()): Year {
  const year = date.getFullYear()
  return createYear(year, YearMode.Calendar)
}

/**
 * Returns the start of the day for a given date, i.e. that day at 00:00
 * @param date The date
 * @returns The start of that day
 */
export function endOfDay(date: Date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999)
}

export function getDaysOfYearWithMonths(year: number) {
  const months = []
  for (let month = 0; month < 12; month++) {
    const daysInMonth = dayjs(`${year}-${month + 1}-01`).daysInMonth()
    const days = []
    for (let day = 1; day <= daysInMonth; day++) {
      days.push(dayjs(`${year}-${month + 1}-${day}`).format("YYYY-MM-DD"))
    }
    months.push({
      month: dayjs(`${year}-${month + 1}-01`).format("MMMM"),
      days,
    })
  }
  return months
}

export function getDaysBetween(date1: Date, date2: Date, includeSameDay = false): BaseMonth[] {
  let start = dayjs(date1)
  let end = dayjs(date2)

  if (start.isSame(end, "D")) {
    return includeSameDay
      ? [
          {
            month: start.format("MMMM"),
            days: [{ date: start.format("YYYY-MM-DD"), dayName: start.day() }],
          },
        ]
      : []
  } else if (start.isAfter(end)) {
    const temp = start
    start = end
    end = temp
  }

  const daysByMonth: BaseMonth[] = []
  let currentMonth: BaseMonth = { month: "", days: [] }

  let currentDate = start
  while (currentDate.isBefore(end) || currentDate.isSame(end, "day")) {
    const month = currentDate.format("MMMM")
    const day = currentDate.format("YYYY-MM-DD")
    const dayName = currentDate.day()

    if (!currentMonth || currentMonth.month !== month) {
      currentMonth = { month, days: [] }
      daysByMonth.push(currentMonth)
    }

    currentMonth.days.push({ date: day, dayName })
    currentDate = currentDate.add(1, "day")
  }

  return daysByMonth
}

export function isBetween(date: string, year: Year): boolean {
  return (
    (dayjs(date).isAfter(year.start) || dayjs(date).isSame(year.start)) &&
    (dayjs(date).isBefore(year.end) || dayjs(date).isSame(year.end))
  )
}
export function formatDateToNaiveDate(date: Date) {
  return dayjs(date).format("YYYY-MM-DD")
}

/**
 * Finds the closest date in an array of dates to a target date.
 * @param {string} targetDateStr - The target date in string format (YYYY-MM-DD).
 * @param {string[]} dateArray - An array of dates in string format (YYYY-MM-DD).
 * @returns {string | null} The closest date in string format (YYYY-MM-DD), or null if the dateArray is empty.
 */
export function findClosestDate(targetDateStr: string, dateArray: string[]): string | null {
  const targetDate = dayjs(targetDateStr)

  let closestDate = null
  let smallestDiff = Infinity

  for (const dateStr of dateArray) {
    const currentDate = dayjs(dateStr)
    const diff = Math.abs(currentDate.diff(targetDate))

    if (diff < smallestDiff) {
      smallestDiff = diff
      closestDate = currentDate
    }
  }

  if (closestDate) {
    return closestDate.format("YYYY-MM-DD")
  }
  return null
}
// Function to get dates between 'from' and 'to' including them
export function getDatesBetween(from: string, to: string) {
  const fromDate = dayjs(from)
  const toDate = to ? dayjs(to) : fromDate
  const dates = []

  for (
    let date = fromDate;
    date.isBefore(toDate) || date.isSame(toDate);
    date = date.add(1, "day")
  ) {
    dates.push(date.format("YYYY-MM-DD"))
  }

  return dates
}
export function isWeekendName(dayName: Weekday): boolean {
  return [Weekday.Saturday, Weekday.Sunday].includes(dayName)
}

export function getOverlapType<T extends { from: Dayjs; to: Dayjs; absence: boolean }>(
  newAppointment: T,
  existingAppointment: T
): OverlapType {
  const newFromDate = newAppointment.from
  const newToDate = newAppointment.to
  const existingFromDate = existingAppointment.from
  const existingToDate = existingAppointment.to

  if (!existingAppointment.absence || !newAppointment.absence) return OverlapType.NoOverlap

  if (newToDate.isBefore(existingFromDate) || newFromDate.isAfter(existingToDate))
    return OverlapType.NoOverlap
  else if (newFromDate.isSame(existingFromDate) && newToDate.isSame(existingToDate))
    return OverlapType.ExactOverlap
  else if (
    (newFromDate.isBefore(existingFromDate) || newFromDate.isSame(existingFromDate)) &&
    (newToDate.isAfter(existingToDate) || newToDate.isSame(existingToDate))
  )
    return OverlapType.CompleteOverlap
  else if (
    (newFromDate.isBefore(existingFromDate) || newFromDate.isSame(existingFromDate)) &&
    newToDate.isBefore(existingToDate)
  )
    return OverlapType.PartialOverlapBefore
  else if (
    newFromDate.isAfter(existingFromDate) &&
    (newToDate.isAfter(existingToDate) || newToDate.isSame(existingToDate))
  )
    return OverlapType.PartialOverlapAfter
  else if (newFromDate.isAfter(existingFromDate) && newToDate.isBefore(existingToDate))
    return OverlapType.PartialOverlap

  return OverlapType.NoOverlap
}

export enum OverlapType {
  /**
   * NoOverlap: There is no overlap between the appointments.
   */
  NoOverlap = "NoOverlap",
  /**
   * ExactMatch: The new appointment exactly matches the existing appointment in terms of start and end times.
   */
  ExactOverlap = "ExactOverlap",
  /**
   * CompleteOverlap: The new appointment completely overlaps the existing appointment.
   */
  CompleteOverlap = "CompleteOverlap",
  /**
   * PartialOverlapBefore: The new appointment starts before and ends within.
   */
  PartialOverlapBefore = "PartialOverlapBefore",
  /**
   * PartialOverlapAfter: The new appointment starts within and ends after.
   */
  PartialOverlapAfter = "PartialOverlapAfter",
  /**
   * CompleteOverlap: The new appointment starts and ends within an existing appointment.
   */
  PartialOverlap = "PartialOverlap",
}
export function convertToNaiveTime(time: string) {
  if (!time) return null

  const isValidTime = dayjs(time, "HH:mm", true).utc().isValid()

  if (isValidTime) {
    const timeObject = dayjs(time, "HH:mm")
    return timeObject.format("HH:mm:ss")
  } else return null
}

export function processNaiveTime(time: string) {
  if (!time && time === "00:00:00") {
    return null
  }

  const isValidTime = dayjs(time, "HH:mm:ss", true).utc().isValid()

  if (isValidTime) {
    const timeObject = dayjs(time, "HH:mm:ss", true)

    return timeObject.format("HH:mm")
  } else return null
}

export function resolveDateByKeyBind(value: string): string | null {
  const today = dayjs()
  switch (value) {
    case "h":
      return formatISO(today)
    case "m":
      return formatISO(today.add(1, "day"))
    case "u":
      return formatISO(today.add(2, "days"))
    case "g":
      return formatISO(today.subtract(1, "day"))
    case "v":
      return formatISO(today.subtract(2, "days"))
    case "j":
      return formatISO(dayjs(`${today.year()}-01-01`))
    case "d":
      return formatISO(dayjs(`${today.year()}-12-31`))
    case "b":
      return formatISO(firstDayOfKindergartenYear())
    case "e":
      return formatISO(lastDayOfKindergartenYear())
    case "n":
      return formatISO(firstDayOfKindergartenYear().add(1, "year"))
    default:
      return null
  }
}

//https://digitale-verwaltung.atlassian.net/browse/AKIT-785
export function customDateMatch(value: string): string | null {
  const validationRegex = new RegExp(/[^-.0-9]/)

  if (validationRegex.test(value)) {
    return null
  }

  const dateRegex = /^(\d{2})[-.]?(\d{2})[-.]?(\d{2}|\d{4})?$/

  const match = value.match(dateRegex)
  if (match) {
    const currentYear = String(new Date().getFullYear())
    const day = match[1]
    const month = match[2]
    let year = match[3] // 24 - 2024

    if (!year) year = currentYear
    else if (year.length === 2) year = `${currentYear.substring(0, 2)}${year}`

    if (Number(padZero(day)) < 1 || Number(padZero(day)) > 31) return null

    if (Number(padZero(month)) < 1 || Number(padZero(month)) > 12) return null

    const formattedDate = localeToISOString(`${day}.${month}.${year}`)
    if (dayjs(formattedDate).isValid()) return formattedDate
  }
  return null
}
export function getMonthsBetweenDatesWithYear(
  entryDate: string | Dayjs,
  exitDate: string | Dayjs,
  formatMonth = "MMMM",
  formatYear = "YYYY"
) {
  const start = dayjs(entryDate)
  const end = dayjs(exitDate)

  if (!start.isValid() || !end.isValid() || start.isAfter(end)) {
    return []
  }

  const months = []
  let current = start.startOf("month")
  while (current.isBefore(end) || current.isSame(end, "month")) {
    months.push({ month: current.format(formatMonth), year: current.format(formatYear) })
    current = current.add(1, "month")
  }

  return months
}
