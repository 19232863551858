import {
  type Characteristic,
  type GroupContract,
  type Child,
  Relation,
  BookingTypeEnum,
  BookingTimeCategory,
  BookingDaysEnum,
  Enrollment,
  type CustodianChild,
  type Booking,
  type CreateContractInput,
  PaymentMethod,
} from "@/graphql/types"
import { i18n } from "@/i18n"
import { type MappedChild } from "@/store/child"
import { type TableConfig } from "@/types"
import { getBookingTypeEnum, getBookingTypeEnumFromObject } from "@/utils/buchungen"
import { citizenshipOptions, denominationOptions, genderOptions } from "@/utils/commonOptions"
import { getOptions } from "@/utils/composables/useDropdown"
import { formatDate } from "@/utils/date"
import { type CustodianInput } from "@/views/admin/migration/factories/CustodianFactory"
import { type StandardBookingInput } from "@/views/admin/migration/factories/StandardBookingFactory"

export enum MaritalStatus {
  LP = "LP", // eingetragene Lebenspartnerschaft
  GS = "GS", // geschieden
  GL = "GL", // getrennt lebend
  LD = "LD", // ledig
  NV = "NV", // nicht verheiratet
  VH = "VH", // verheiratet
  VW = "VW", // verwitwet
  WG = "WG", // wiederverheiratet / geschieden
  WW = "WW", // wiederverheiratet / verwitwet
}

//TODO: Replace if backend provides it
export enum Beitragsschema {
  elternbeitrag = "elternbeitrag",
  ferienbuchungen = "ferienbuchungen",
  notbetreuung = "notbetreuung",
  essensgeld = "essensgeld",
}
//TODO: Replace if backend provides it
export enum Beitragszahler {
  JobcenterMemmingen = "0",
  Stiftung = "1",
}

//TODO: Replace if backend provides it

export const Beitragsart = {
  "0": "0",
  "1": "1",
  "2": "2",
  "3": "3",
  "4": "4",
  "5": "5",
  "6": "6",
  "7": "7",
  "8": "8",
  "9": "9",
  "10": "10",
  "11": "11",
  "12": "12",
  "13": "13",
  "14": "14",
  "15": "15",
  "16": "16",
  "17": "17",
  "18": "18",
}

export const childRelationOptions = getOptions(Relation, "bezugsperson")
export const maritalStatusOptions = getOptions(MaritalStatus, "marital_status")
export const bookingTypeOptions = getOptions(
  BookingTypeEnum,
  "kinder.kinder.view.reservation.types"
)
export const bookingTimeCategoryOptions = getOptions(
  BookingTimeCategory,
  "kinder.kinder.view.reservation.categorie_types"
)
export const bookingDaysPolicyOptions = getOptions(
  BookingDaysEnum,
  "kinder.kinder.view.reservation.booking_day_policy"
)
export const bookingEnrollmentOptions = getOptions(
  Enrollment,
  "kinder.kinder.view.reservation.enrollements"
)
//TODO: Change names to eng when backend provides them with enums
export const beitragsschemaOptions = getOptions(Beitragsschema, "beitragsschema")
export const beitragszahlerOptions = getOptions(Beitragszahler, "beitragszahler")
export const paymentMethodOptions = getOptions(PaymentMethod, "payment_methods")
export const beitragsartOptions = getOptions(Beitragsart, "beitragsart")

const config: TableConfig<Child, MappedChild> = {
  columnDefs: [
    { key: "n", header: i18n.global.t("labels.id") },
    { key: "person.firstname", header: i18n.global.t("kinder.kinder.felder.vorname.label") },
    { key: "person.lastname", header: i18n.global.t("kinder.kinder.felder.nachname.label") },
    {
      key: "person.gender",
      format: (child) => child.person?.gender && i18n.global.t(`genders.${child.person?.gender}`),
      header: i18n.global.t("kinder.kinder.felder.geschlecht.label"),
    },
    { key: "address.street", header: i18n.global.t("kinder.kinder.felder.strasse.label") },
    { key: "address.city", header: i18n.global.t("kinder.kinder.felder.ort.label") },
    { key: "address.zipCode", header: i18n.global.t("kinder.kinder.felder.plz.label") },
    {
      key: "address.country",
      format: (child) =>
        child.address?.country && i18n.global.t(`countries_code.${child.address?.country}`),
      header: i18n.global.t("kinder.kinder.felder.land.label"),
      active: false,
    },
    {
      key: "birth.date",
      format: (child) => child.birth?.date && formatDate(child.birth?.date),
      header: i18n.global.t("kinder.kinder.felder.geburtsdatum.label"),
    },
    { key: "contact.phone", header: i18n.global.t("kinder.kinder.felder.telefon.label") },
  ],
  filterableColumnDefs: [
    {
      key: "person.firstname", //default
      dataKey: "person.firstname",
      header: i18n.global.t("kinder.kinder.page.filter.felder.vorname.label"),
      filterType: "string",
    },
    {
      key: "person.lastname", //default
      dataKey: "person.lastname",
      header: i18n.global.t("kinder.kinder.page.filter.felder.nachname.label"),
      filterType: "string",
    },
    {
      key: "plz",
      dataKey: "address.zipCode",
      header: i18n.global.t("kinder.kinder.page.filter.felder.plz.label"),
      filterType: "string",
    },
    {
      key: "ort",
      dataKey: "address.city",
      header: i18n.global.t("kinder.kinder.page.filter.felder.ort.label"),
      filterType: "string",
    },
    {
      key: "ortsteil",
      dataKey: "address.district",
      header: i18n.global.t("kinder.kinder.page.filter.felder.ortsteil.label"),
      filterType: "string",
    },
    {
      key: "groups", //default
      dataKey: "childGroups",
      fields: ["contracts.groups.group.id", "contracts.groups.group.name"],
      requiredPermissions: ["group:list"],
      header: i18n.global.t("kinder.kinder.page.filter.felder.gruppe.label"),
      filterType: "select",
      matchModes: ["contains"],
      component: () => import("@/components/context/GroupsSelect.vue"),
    },
    {
      key: "aktion_gruppe",
      dataKey: "childActionGroups",
      requiredPermissions: ["group:list"],
      fields: [
        "contracts.groups.group.id",
        "contracts.groups.group.name",
        "contracts.groups.group.actionGroup",
      ],
      header: i18n.global.t("kinder.kinder.page.filter.felder.aktion_gruppe.label"),
      filterType: "select",
      matchModes: ["contains"],
      component: () => import("@/components/context/ActionGroupsSelect.vue"),
    },
    {
      key: "characteristics",
      dataKey: "characteristicIds",
      requiredPermissions: ["characteristic:list"],
      fields: ["characteristics.characteristic.id"],
      header: i18n.global.t("kinder.kinder.page.filter.felder.merkmale.label"),
      filterType: "select",
      matchModes: ["contains"],
      component: () => import("@/components/context/CharacteristicsSelect.vue"),
    },
    {
      key: "anmeldung",
      dataKey: "contractsRegistrationDates",
      fields: ["contracts.registrationDate"],
      header: i18n.global.t("kinder.kinder.page.filter.felder.anmeldung.label"),
      filterType: "date_range",
      matchModes: ["dateRangeContains"],
    },
    {
      key: "startOfMemberships", //default
      dataKey: "startOfContractDates",
      fields: ["contracts.startOfContract"],
      header: i18n.global.t("kinder.kinder.page.filter.felder.aufnahme.label"),
      filterType: "date_range",
      matchModes: ["dateRangeContains"],
    },
    {
      key: "endOfMemberships", //default
      dataKey: "endOfContractDates",
      fields: ["contracts.endOfContract"],
      header: i18n.global.t("kinder.kinder.page.filter.felder.austritt.label"),
      filterType: "date_range",
      matchModes: ["dateRangeContains"],
    },
    {
      key: "age", //default
      dataKey: "age",
      header: i18n.global.t("kinder.kinder.page.filter.felder.alter.label"),
      fields: ["birth.date"],
      filterType: "number_range",
    },
    {
      key: "birth.date", //default
      dataKey: "birth.date",
      header: i18n.global.t("kinder.kinder.page.filter.felder.geburtsdatum.label"),
      filterType: "date_range",
    },
    {
      key: "konfession",
      dataKey: "person.denomination",
      header: i18n.global.t("kinder.kinder.page.filter.felder.konfession.label"),
      filterType: "select",
      options: denominationOptions,
    },
    {
      key: "staatsangehoerigkeit",
      dataKey: "person.citizenships",
      header: i18n.global.t("kinder.kinder.page.filter.felder.staatsangehoerigkeit.label"),
      filterType: "select",
      options: citizenshipOptions,
      disableSort: true,
      prioritizedKeys: ["001"],
    },
    {
      key: "anzahl_geschwister",
      dataKey: "anzahl_geschwister",
      fields: ["siblings.sibling.id", "externalSiblings.firstname"],
      header: i18n.global.t("kinder.kinder.page.filter.felder.anzahl_geschwister.label"),
      filterType: "number_range",
    },
    {
      key: "person.gender", //default
      dataKey: "person.gender",
      header: i18n.global.t("kinder.kinder.page.filter.felder.geschlecht.label"),
      filterType: "select",
      options: genderOptions,
    },
    {
      key: "kind_id",
      dataKey: "childId",
      header: i18n.global.t("kinder.kinder.page.filter.felder.kind_id.label"),
      filterType: "string",
    },
    {
      key: "buchungskriterien",
      dataKey: "bookingsCategories",
      fields: ["contracts.bookings.type"],
      header: i18n.global.t("kinder.kinder.page.filter.felder.buchungskriterien.label"),
      filterType: "select",
      options: bookingTypeOptions,
    },
    {
      key: "stundenkategorie",
      dataKey: "bookingHoursCategories",
      fields: ["contracts.bookings.timeFactor.category"],
      header: i18n.global.t("kinder.kinder.page.filter.felder.stundenkategorie.label"),
      filterType: "select",
      options: bookingTimeCategoryOptions,
      disableSort: true,
    },
    {
      key: "zuschussgemeinde",
      dataKey: "commune.id",
      header: i18n.global.t("kinder.kinder.page.filter.felder.zuschussgemeinde.label"),
      filterType: "select",
      component: () => import("@/components/context/CommuneSelect.vue"),
    },
    {
      key: "buchungsbemerkung",
      dataKey: "bookingRemarks",
      fields: ["contracts.bookings.remarks"],
      header: i18n.global.t("kinder.kinder.page.filter.felder.buchungsbemerkung.label"),
      filterType: "string",
    },
    {
      key: "zeitbeschreibung",
      dataKey: "bookingComments",
      fields: ["contracts.bookings.times.comments"],
      header: i18n.global.t("kinder.kinder.page.filter.felder.zeitbeschreibung.label"),
      filterType: "string",
    },
    {
      key: "schule",
      dataKey: "bookingSchools",
      fields: ["contracts.bookings.schoolData.school.id"],
      header: i18n.global.t("kinder.kinder.page.filter.felder.schule.label"),
      filterType: "select",
      component: () => import("@/components/context/SchoolSelect.vue"),
    },
    {
      key: "klasse",
      dataKey: "bookingClasses",
      fields: ["contracts.bookings.schoolData.className"],
      header: i18n.global.t("kinder.kinder.page.filter.felder.klasse.label"),
      filterType: "string",
    },
  ],
}

export default config

export const CharacteristicTableConfig: TableConfig<Characteristic, Characteristic> = {
  columnDefs: [
    { key: "n", header: i18n.global.t("labels.id") },
    { key: "name", header: i18n.global.t("labels.name") },
  ],
}

export const CustodianTableConfig: TableConfig<CustodianChild, CustodianChild> = {
  columnDefs: [
    {
      key: "relation",
      header: i18n.global.t("kinder.kinder.felder.local_guardians.relation_to_child.label"),
      format: (data) => (data.relation ? i18n.global.t(`bezugsperson.${data.relation}`) : ""),
    },
    {
      key: "custodian.person.lastname",
      header: i18n.global.t("kinder.kinder.felder.local_guardians.nachname.label"),
    },
    {
      key: "custodian.person.firstname",
      header: i18n.global.t("kinder.kinder.felder.local_guardians.vorname.label"),
    },
    {
      key: "custodian.person.title",
      header: i18n.global.t("labels.title"),
      format: (data) =>
        data?.custodian?.person?.title
          ? i18n.global.t(`titles.${data.custodian.person.title}`)
          : "",
    },
  ],
}

export const MigrationCustodianTableConfig: TableConfig<CustodianInput, CustodianInput> = {
  columnDefs: [
    {
      key: "custodianChild.relation",
      header: i18n.global.t("kinder.kinder.felder.local_guardians.relation_to_child.label"),
      format: (data) =>
        data?.custodianChild?.relation
          ? i18n.global.t(`bezugsperson.${data.custodianChild.relation}`)
          : "",
    },
    {
      key: "custodian.person.lastname",
      header: i18n.global.t("kinder.kinder.felder.local_guardians.nachname.label"),
    },
    {
      key: "custodian.person.firstname",
      header: i18n.global.t("kinder.kinder.felder.local_guardians.vorname.label"),
    },
    {
      key: "custodian.person.title",
      header: i18n.global.t("labels.title"),
      format: (data) =>
        data?.custodian?.person?.title
          ? i18n.global.t(`titles.${data.custodian.person.title}`)
          : "",
    },
  ],
}

export const BookingTableConfig: TableConfig<Booking, Booking> = {
  columnDefs: [
    {
      key: "type",
      header: i18n.global.t("kinder.kinder.felder.bookings.buchungsart.label"),
      format: (data) =>
        i18n.global.t(
          `kinder.kinder.view.reservation.types.${data.type ?? getBookingTypeEnumFromObject(data.typeObject)}`
        ),
    },
    {
      key: "from",
      format: (data) => (data?.from ? formatDate(data.from) : ""),
      header: i18n.global.t("personal.mitarbeiter.view.booking.felder.von.label"),
    },
    {
      key: "to",
      format: (data) => (data?.to ? formatDate(data.to) : ""),
      header: i18n.global.t("personal.mitarbeiter.view.booking.felder.bis.label"),
    },
  ],
}

export const MigrationBookingTableConfig: TableConfig<StandardBookingInput, StandardBookingInput> =
  {
    columnDefs: [
      {
        key: "type",
        header: i18n.global.t("kinder.kinder.felder.bookings.buchungsart.label"),
        format: (data) =>
          i18n.global.t(
            `kinder.kinder.view.reservation.types.${getBookingTypeEnum(data?.input?.typeObject)}`
          ),
      },
      {
        key: "from",
        format: (data) => (data?.input?.from ? formatDate(data.input.from) : ""),
        header: i18n.global.t("personal.mitarbeiter.view.booking.felder.von.label"),
      },
      {
        key: "to",
        format: (data) => (data?.input?.to ? formatDate(data.input.to) : ""),
        header: i18n.global.t("personal.mitarbeiter.view.booking.felder.bis.label"),
      },
    ],
  }

export const GroupTableConfig: TableConfig<GroupContract, GroupContract> = {
  columnDefs: [
    { key: "group.name", header: i18n.global.t("kinder.kinder.felder.gruppen.gruppe.label") },
    {
      key: "movement.entry",
      header: i18n.global.t("kinder.kinder.felder.gruppen.von.label"),
      format: (data) => (data.movement.entry ? formatDate(data.movement.entry) : ""),
    },
    {
      key: "movement.exit",
      header: i18n.global.t("kinder.kinder.felder.gruppen.bis.label"),
      format: (data) => (data.movement.exit ? formatDate(data.movement.exit) : ""),
    },
    { key: "notes", header: i18n.global.t("kinder.kinder.felder.gruppen.anmerkungen.label") },
  ],
}

export const ContractTableConfig: TableConfig<CreateContractInput, CreateContractInput> = {
  columnDefs: [
    {
      key: "registrationDate",
      header: i18n.global.t("kinder.kinder.view.contract.felder.registrationDate.label"),
    },
    {
      key: "startOfContract",
      header: i18n.global.t("kinder.kinder.felder.vertrag.laufzeit_von.label"),
      format: (data) => (data.startOfContract ? formatDate(data.startOfContract) : ""),
    },
    {
      key: "endOfContract",
      header: i18n.global.t("kinder.kinder.felder.vertrag.laufzeit_bis.label"),
      format: (data) => (data.endOfContract ? formatDate(data.endOfContract) : ""),
    },
    { key: "remarks", header: i18n.global.t("kinder.kinder.view.contract.felder.remarks.label") },
  ],
}
