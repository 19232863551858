import { type Ref } from "vue"

import { type BaseEntity } from "@/types"

export function useTableItemNavigation<TEntity extends BaseEntity>(
  currentId: Ref<string | undefined>,
  list: Ref<TEntity[] | undefined>,
  onNavigated: (item: TEntity) => void
) {
  async function nextRecord() {
    try {
      if (list.value && currentId.value) {
        const currentIndex = list.value.findIndex((item) => item.id === currentId.value)
        if (currentIndex === -1) {
          throw Error("Something went wrong, cannot proceed to the next record.")
        }
        const nextIndex = (currentIndex + 1) % list.value.length
        const nextRecord = list.value[nextIndex]
        await onNavigated(nextRecord)
      }
    } catch (e) {
      console.error(e)
    }
  }

  async function previousRecord() {
    try {
      if (list.value && currentId.value) {
        const currentIndex = list.value.findIndex((item) => item.id === currentId.value)
        if (currentIndex === -1) {
          throw Error("Something went wrong, cannot proceed to the previous record.")
        }
        const previousIndex = (currentIndex - 1 + list.value.length) % list.value.length
        const previousRecord = list.value[previousIndex]
        await onNavigated(previousRecord)
      }
    } catch (e) {
      console.error(e)
    }
  }

  return {
    nextRecord,
    previousRecord,
  }
}
