import { FilterService } from "@primevue/core/api"
import { type AsyncComponentLoader, type ComponentPublicInstance } from "vue"

export type FilterType =
  | "select"
  | "string"
  | "multi_select"
  | "date"
  | "date_range"
  | "number"
  | "number_range"
  | "bool"
//| "time"
//| "check"
//| "color"

export const COMPONENT_BY_FILTER_TYPE: {
  [key in FilterType]: AsyncComponentLoader<new () => ComponentPublicInstance>
} = {
  string: () => import("@/components/common/BaseInput.vue"),
  select: () => import("@/components/common/BaseSelect.vue"),
  multi_select: () => import("@/components/common/BaseMultiSelect.vue"),
  date: () => import("@/components/common/BaseDateInput.vue"),
  date_range: () => import("@/components/common/BaseDateRangeInput.vue"),
  number: () => import("@/components/common/BaseNumberInput.vue"),
  number_range: () => import("@/components/common/BaseNumberRangeInput.vue"),
  bool: () => import("@/components/common/BaseButtonSeclect.vue"),
  //time: () => import("@/components/common/BaseInputTime.vue"),
  //check: () => import("@/components/common/BaseCheckbox.vue"),
  //color: () => import("@/components/common/BaseColorSelector.vue"),
} as const

export const MATCH_MODES_BY_FILTER_TYPE: { [key in FilterType]: FilterOption[] } = {
  string: ["equals"],
  select: ["equals"],
  multi_select: ["in"],
  date: ["dateIs"],
  date_range: [],
  number: ["equals"],
  number_range: [],
  bool: ["equals"],
  //time: ["DATE_IS"],
  //check: ["EQUALS"],
  //color: ["EQUALS"],
} as const

export const CustomFilters = {
  caseSensitiveEquals: (value: any, filter: any) => {
    if (filter === undefined || filter === null || filter.trim() === "") {
      return true
    }

    if (value === undefined || value === null) {
      return false
    }

    return value.toString() === filter.toString()
  },
  caseSensitiveContains: (value: any, filter: any) => {
    if (!Array.isArray(value)) throw new Error("Wrong use of caseSensitiveContain!")
    if (filter === undefined || filter === null || filter.trim() === "") {
      return true
    }

    if (value === undefined || value === null) {
      return false
    }

    return value.includes(filter.toString())
  },
  dateRangeContains: (value: Date[], filter: { from?: Date; to?: Date } | null) => {
    if (!value || !filter) return false

    const { from, to } = filter
    return value.some((date) => {
      if (from && to) return date >= from && date <= to
      else if (from) return date >= from
      else if (to) return date <= to
    })
  },
} as const

Object.entries(CustomFilters).forEach(([key, fn]) => {
  FilterService.register(key, fn)
})

export type FilterOption = keyof FilterService.filters | keyof typeof CustomFilters
