import { useMutation } from "@vue/apollo-composable"
import { defineStore } from "pinia"
import { computed } from "vue"

import { useCustomerStore } from "./customer"
import { useInstitutionStore } from "./institution"
import { useOrganizationStore } from "./organization"
import { useSessionStore } from "./session"

import CharacteristicListQuery from "@/graphql/characteristic/CharacteristicList.gql"
import CreateCharacteristicMutation from "@/graphql/characteristic/CreateCharacteristic.gql"
import CreateChildCharacteristicAssignmentMutation from "@/graphql/characteristic/CreateChildCharacteristic.gql"
import RemoveCharacteristicsMutation from "@/graphql/characteristic/RemoveCharacteristics.gql"
import RemoveChildCharacteristicAssignmentsMutation from "@/graphql/characteristic/RemoveChildCharacteristic.gql"
import {
  Table,
  type Characteristic,
  type CharacteristicList,
  type ChildCharacteristic,
  type QueryRootCharacteristicsArgs,
  type MutationRootCreateCharacteristicArgs,
  type MutationRootRemoveCharacteristicsArgs,
  type MutationRootCreateChildCharacteristicAssignmentArgs,
  type MutationRootRemoveChildCharacteristicAssignmentsArgs,
  type Child,
} from "@/graphql/types"
import { useApi } from "@/utils/composables/apollo/useApi"
import { useHandleMutationErrors } from "@/utils/composables/useHandleMutationErrors"
import { type StrictEntityId } from "@/utils/entity"

export type CharacteristicListResult = { characteristics: CharacteristicList }
export type CharacteristicCreateResult = { createCharacteristic: Characteristic }
export type CharacteristicRemoveResult = { removeCharacteristics: number }

export type ChildCharacteristicAssigmentCreateResult = {
  createChildCharacteristicAssignment: ChildCharacteristic
}
export type ChildCharacteristicAssigmentRemoveResult = {
  removeChildCharacteristicAssignments: number
}

export const characteristicResultMap = {
  getList: (result: CharacteristicListResult) => result.characteristics,
  getCreated: (result: CharacteristicCreateResult) => result.createCharacteristic,
  getUpdated: undefined,
  getRemovedCount: (result: CharacteristicRemoveResult) => result.removeCharacteristics,
  getLinkedCount: undefined,
}

export const useCharacteristicStore = defineStore(Table.Characteristics, () => {
  const sessionStore = useSessionStore()
  const customerStore = useCustomerStore()
  const organizationStore = useOrganizationStore()
  const institutionStore = useInstitutionStore()
  const { handleMutationErrors } = useHandleMutationErrors()

  const listQueryOptions = computed(() => ({
    enabled:
      sessionStore.hasRoles(["child:view", "child:create", "child:update"], true) &&
      !!sessionStore.institutionFilter.institution,
  }))

  const listQueryVariables = computed(() => ({
    context: sessionStore.institutionFilter,
    ...sessionStore.accessLevelListPermissions,
  }))

  const viewQueryVariables = computed(() => ({
    ...sessionStore.accessLevelViewPermissions,
  }))

  const api = useApi<
    Characteristic,
    "characteristics",
    CharacteristicListResult,
    QueryRootCharacteristicsArgs,
    undefined,
    {},
    CharacteristicCreateResult,
    MutationRootCreateCharacteristicArgs,
    undefined,
    {},
    CharacteristicRemoveResult,
    MutationRootRemoveCharacteristicsArgs
  >({
    typename: "Characteristic",
    operations: {
      list: CharacteristicListQuery,
      getById: undefined,
      create: CreateCharacteristicMutation,
      update: undefined,
      remove: RemoveCharacteristicsMutation,
      link: undefined,
    },
    resultMap: characteristicResultMap,
    mapRemovedIds: (variables) => variables.ids,
    listQueryVariables,
    listQueryOptions,
    mutationVariables: viewQueryVariables,
  })

  const list = computed<Characteristic[]>(() => api.listResult?.characteristics?.items ?? [])

  const { mutate: createChildCharacteristicAssignment } = useMutation<
    ChildCharacteristicAssigmentCreateResult,
    MutationRootCreateChildCharacteristicAssignmentArgs
  >(CreateChildCharacteristicAssignmentMutation, {
    errorPolicy: "all",
    update: (cache, result) => {
      if (!result.data?.createChildCharacteristicAssignment) return

      cache.modify<Child>({
        id: cache.identify({
          __typename: "Child",
          id: result.data?.createChildCharacteristicAssignment?.child?.id,
        }),
        fields: {
          characteristics(existingRefs = [], { toReference }) {
            try {
              const newCharacteristic = result?.data?.createChildCharacteristicAssignment
                ? toReference(result?.data?.createChildCharacteristicAssignment)
                : null

              if (newCharacteristic) {
                return existingRefs && existingRefs.length
                  ? [...existingRefs, newCharacteristic]
                  : [newCharacteristic]
              }
              return existingRefs
            } catch (error) {
              console.error("Cache update error:", error)
              return existingRefs
            }
          },
        },
      })
    },
  })

  const { mutate: removeChildCharacteristicAssignment } = useMutation<
    ChildCharacteristicAssigmentRemoveResult,
    MutationRootRemoveChildCharacteristicAssignmentsArgs & { childId: StrictEntityId }
  >(RemoveChildCharacteristicAssignmentsMutation, {
    errorPolicy: "all",
    update: (cache, _result, options) => {
      const { ids, childId } = options.variables || {}
      for (const assignmentId of ids ?? []) {
        cache.modify<Child>({
          id: cache.identify({
            __typename: "Child",
            id: childId,
          }),
          fields: {
            characteristics(existingRefs = [], { readField }) {
              try {
                return existingRefs?.filter((ref) => {
                  const id = readField("id", ref)
                  if (typeof id !== "string") return false
                  return id !== assignmentId
                })
              } catch (error) {
                console.error("Cache update error:", error)
                return existingRefs
              }
            },
          },
        })
      }
    },
  })

  async function createChildCharacteristic(characteristicId: string, childId: string) {
    const result = await createChildCharacteristicAssignment({
      context: sessionStore.institutionFilter,
      input: {
        childId,
        characteristicId,
      },
    })

    if (handleMutationErrors(result)) return null
    return result
  }

  async function removeChildCharacteristics(
    ChildcharacteristicsIds: StrictEntityId[],
    childId: StrictEntityId
  ) {
    if (ChildcharacteristicsIds.length === 0) return
    const result = await removeChildCharacteristicAssignment({
      ids: ChildcharacteristicsIds,
      childId,
    })
    if (handleMutationErrors(result)) return null
    return result
  }

  function createMultipleChildCharacteristics(childId: string, characteristicIds: string[]) {
    return Promise.all(characteristicIds.map((id) => createChildCharacteristic(id, childId)))
  }

  customerStore.api.addRemoveReducer(
    api.getListFilterRemoveReducer(
      (ids) => (item) => !ids.find((cid) => cid === item.institution?.customer?.cid)
    )
  )
  organizationStore.api.addRemoveReducer(
    api.getListFilterRemoveReducer(
      (ids) => (item) => !ids.find((oid) => oid === item.organization?.oid)
    )
  )
  institutionStore.api.addRemoveReducer(
    api.getListFilterRemoveReducer(
      (ids) => (item) => !ids.find((iid) => iid === item.institution?.iid)
    )
  )
  api.addRemoveReducer(api.getListFilterRemoveReducer((ids) => (item) => !ids.includes(item.id)))

  return {
    api,
    list,

    createChildCharacteristic,
    removeChildCharacteristics,
    createMultipleChildCharacteristics,
    listQueryOptions,
    listQueryVariables,
  }
})
