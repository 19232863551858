import isEqual from "lodash/isEqual"
import isObject from "lodash/isObject"
import transform from "lodash/transform"

import { isValidTimeString, parseTime } from "./date"

import { type TimeRange, type Maybe, type Times } from "@/graphql/types"

export type WeekdayKey = Exclude<keyof Times, "__typename">

export function getTimeRangeHours(range: Maybe<TimeRange> | undefined): number {
  if (!range || !isValidTimeString(range.start) || !isValidTimeString(range.end)) return 0

  const parsedStart = parseTime(range.start!)
  const parsedEnd = parseTime(range.end!)
  return Math.max(0, parsedEnd.diff(parsedStart, "minutes") / 60)
}

export function getWeekdayHours(ranges: Maybe<TimeRange[]> | undefined): number {
  if (!ranges) return 0

  return Object.values(ranges).reduce((acc, range) => {
    return acc + getTimeRangeHours(range)
  }, 0)
}

export function getWeektimesHours(times: Maybe<Times> | undefined): number {
  if (!times) return 0

  times = { ...times }
  delete times.__typename

  return (Object.values(times) as Maybe<TimeRange[]>[]).reduce((acc, ranges) => {
    return acc + getWeekdayHours(ranges)
  }, 0)
}

/**
 * Compares two objects and returns the differences between them as a new object.
 */

export function difference<U extends Record<string, unknown>, T extends U>(object: T, base: T): U {
  function changes(object: T, base: T): U {
    return transform(object, (result, value, key) => {
      if (!isEqual(value, base[key])) {
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        ;(result as any)[key] =
          isObject(value) && isObject(base[key]) ? changes(value as T, base[key] as T) : value
      }
    })
  }
  return changes(object, base)
}

/**
 * Compares two objects for deep equality, meaning it checks if the objects have the same properties and values, recursively.
 *
 * @param {Record<any, any>} obj1 - The first object to compare.
 * @param {Record<any, any>} obj2 - The second object to compare.
 * @returns {boolean} - `true` if the objects are deeply equal, `false` otherwise.
 *
 * @example
 * const obj1 = { a: 1, b: { c: 2 } };
 * const obj2 = { a: 1, b: { c: 2 } };
 * const result = deepEqual(obj1, obj2); // true
 */
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export function deepEqual(obj1: Record<any, any>, obj2: Record<any, any>): boolean {
  if (obj1 === obj2) {
    return true
  }
  if (typeof obj1 !== "object" || typeof obj2 !== "object" || obj1 === null || obj2 === null) {
    return false
  }
  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)
  if (keys1.length !== keys2.length) {
    return false
  }
  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false
    }
  }
  return true
}
export function removeTypename(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map(removeTypename)
  } else if (obj && typeof obj === "object") {
    return Object.fromEntries(
      Object.entries(obj)
        .filter(([key]) => key !== "__typename")
        .map(([key, value]) => [key, removeTypename(value)])
    )
  }
  return obj
}

/**
 * Merges values from obj2 into obj1 recursively, preserving the structure of obj1.
 * If a key exists in obj1 and obj2, and both values are objects, it merges them recursively.
 * Otherwise, it takes the value from obj2 if it exists.
 *
 * @param {T} obj1 - The base object whose structure is preserved.
 * @param {T} obj2 - The object providing values to override obj1's properties.
 * @returns {T} A new object with values from obj2 where applicable.
 */
export function overrideValuesFromObject<T extends Object>(obj1: T, obj2: T): T {
  const result = { ...obj1 }

  for (const key in obj1) {
    if (Object.prototype.hasOwnProperty.call(obj1, key)) {
      if (
        typeof obj1[key] === "object" &&
        obj1[key] !== null &&
        key in obj2 &&
        typeof obj2[key] === "object" &&
        obj2[key] !== null
      ) {
        result[key] = overrideValuesFromObject(obj1[key], obj2[key])
      } else if (key in obj2) {
        result[key] = obj2[key]
      }
    }
  }

  return result
}
