import Accordion from "primevue/accordion"
import AccordionContent from "primevue/accordioncontent"
import AccordionHeader from "primevue/accordionheader"
import AccordionPanel from "primevue/accordionpanel"
import AutoComplete from "primevue/autocomplete"
import Badge from "primevue/badge"
import Button from "primevue/button"
import ButtonGroup from "primevue/buttongroup"
import Card from "primevue/card"
import Chart from "primevue/chart"
import Checkbox from "primevue/checkbox"
import Chip from "primevue/chip"
import ColorPicker from "primevue/colorpicker"
import Column from "primevue/column"
import DataTable from "primevue/datatable"
import DataView from "primevue/dataview"
import DatePicker from "primevue/datepicker"
import Dialog from "primevue/dialog"
import Divider from "primevue/divider"
import FileUpload from "primevue/fileupload"
import FloatLabel from "primevue/floatlabel"
import Fluid from "primevue/fluid"
import IconField from "primevue/iconfield"
import InputGroup from "primevue/inputgroup"
import InputIcon from "primevue/inputicon"
import InputMask from "primevue/inputmask"
import InputNumber from "primevue/inputnumber"
import InputText from "primevue/inputtext"
import Listbox from "primevue/listbox"
import Menu from "primevue/menu"
import Message from "primevue/message"
import MultiSelect from "primevue/multiselect"
import Password from "primevue/password"
import Popover from "primevue/popover"
import ProgressBar from "primevue/progressbar"
import ProgressSpinner from "primevue/progressspinner"
import RadioButton from "primevue/radiobutton"
import Select from "primevue/select"
import SelectButton from "primevue/selectbutton"
import Skeleton from "primevue/skeleton"
import Tab from "primevue/tab"
import TabList from "primevue/tablist"
import TabPanel from "primevue/tabpanel"
import TabPanels from "primevue/tabpanels"
import Tabs from "primevue/tabs"
import Tag from "primevue/tag"
import Textarea from "primevue/textarea"
import TieredMenu from "primevue/tieredmenu"
import Toast from "primevue/toast"
import ToggleButton from "primevue/togglebutton"
import ToggleSwitch from "primevue/toggleswitch"
import Toolbar from "primevue/toolbar"
import Tree from "primevue/tree"
import { VueDraggableNext } from "vue-draggable-next"

import type { App } from "@vue/runtime-core"

import BaseFormControl from "@/components/common/BaseFormControl.vue"
import BaseDialog from "@/views/base/BaseDialog.vue"

export default (app: App): void => {
  app.component("Button", Button)
  app.component("Chart", Chart)
  app.component("Checkbox", Checkbox)
  app.component("Chip", Chip)
  app.component("Column", Column)
  app.component("DataTable", DataTable)
  app.component("Dialog", Dialog)
  app.component("Divider", Divider)
  app.component("IconField", IconField)
  app.component("InputIcon", InputIcon)
  app.component("InputNumber", InputNumber)
  app.component("InputText", InputText)
  app.component("Listbox", Listbox)
  app.component("MultiSelect", MultiSelect)
  app.component("ProgressBar", ProgressBar)
  app.component("ProgressSpinner", ProgressSpinner)
  app.component("RadioButton", RadioButton)
  app.component("Select", Select)
  app.component("Tab", Tab)
  app.component("TabList", TabList)
  app.component("TabPanel", TabPanel)
  app.component("TabPanels", TabPanels)
  app.component("Tabs", Tabs)
  app.component("Tag", Tag)
  app.component("Toolbar", Toolbar)
  app.component("FloatLabel", FloatLabel)
  app.component("Fluid", Fluid)
  app.component("SelectButton", SelectButton)
  app.component("Card", Card)

  app.component("Accordion", Accordion)
  app.component("AccordionContent", AccordionContent)
  app.component("AccordionHeader", AccordionHeader)
  app.component("AccordionPanel", AccordionPanel)
  app.component("Toast", Toast)
  app.component("AutoComplete", AutoComplete)
  app.component("ColorPicker", ColorPicker)
  app.component("FileUpload", FileUpload)
  app.component("Popover", Popover)
  app.component("InputMask", InputMask)
  app.component("Password", Password)
  app.component("ToggleSwitch", ToggleSwitch)
  app.component("Textarea", Textarea)
  app.component("DatePicker", DatePicker)
  app.component("Menu", Menu)
  app.component("Tree", Tree)
  app.component("ToggleButton", ToggleButton)
  app.component("InputGroup", InputGroup)
  app.component("ButtonGroup", ButtonGroup)
  app.component("TieredMenu", TieredMenu)
  app.component("Message", Message)
  app.component("Badge", Badge)
  app.component("DataView", DataView)
  app.component("Skeleton", Skeleton)

  app.component("Draggable", VueDraggableNext)

  app.component("BaseFormControl", BaseFormControl)
  app.component("BaseDialog", BaseDialog)
}
