import { type RouteRecordRaw } from "vue-router"

import { type Permission } from "@/types"

const routes: Array<RouteRecordRaw> = [
  {
    path: "/mitarbeiter",
    name: "EmployeePage",
    component: () => import("./employees/EmployeePage.vue"),
    meta: {
      requiredRoles: <Permission[]>["employee:list", "employee:unlock"],
    },
    children: [
      {
        path: "erstellen",
        name: "EmployeeDialog",
        component: () => import("./employees/EmployeeDialog.vue"),
        meta: {
          requiredRoles: <Permission[]>["employee:unlock"],
        },
      },
    ],
  },
  {
    path: "/mitarbeiter/:id",
    name: "EmployeeEdit",
    component: () => import("./employees/edit/EmployeeView.vue"),
    meta: {
      requiredRoles: <Permission[]>["employee:view", "employee:unlock"],
    },
    redirect: "allgemein",
    children: [
      {
        path: "allgemein",
        name: "EmployeeFormGeneral",
        component: () => import("./employees/edit/forms/GeneralForm.vue"),
        meta: {
          requiredRoles: <Permission[]>["employee:unlock"],
        },
        children: [
          {
            path: "kategorie",
            name: "EmployeeFormEditCategoryDialog",
            component: () => import("../stammdaten/address/CreateCategoryDialog.vue"),
            props: { isCreateDialog: true },
            meta: {
              requiredRoles: <Permission[]>["category:create", "employee:unlock"],
            },
          },
        ],
      },
      {
        path: "gruppen",
        name: "EmployeeGroup",
        component: () => import("./employees/edit/GroupTableData.vue"),
      },
      {
        path: "gruppen/erstellen",
        name: "EmployeeGroupCreate",
        component: () => import("./employees/edit/forms/ManageGroup.vue"),
      },
      {
        path: "gruppen/:employeeGroupId",
        name: "EmployeeGroupEdit",
        component: () => import("./employees/edit/forms/ManageGroup.vue"),
      },
      {
        path: "funktionen",
        name: "EmployeeQualification",
        component: () => import("./employees/edit/QualifikationTableData.vue"),
      },
      {
        path: "funktionen/erstellen",
        name: "EmployeeFormQualificationCreate",
        component: () => import("./employees/edit/forms/ManageQualifikation.vue"),
        children: [
          {
            path: "zusatzfunktionen-qualifikationen/erstellen",
            name: "EmployeeFormQualificationDialogCreate",
            props: { isCreateDialog: true },
            component: () => import("../stammdaten/qualification/QualificationDialog.vue"),
            meta: {
              requiredRoles: <Permission[]>["qualification:create"],
            },
          },
        ],
      },

      {
        path: "funktionen/:qualificationId",
        name: "EmployeeFormQualificationEdit",
        component: () => import("./employees/edit/forms/ManageQualifikation.vue"),
        children: [
          {
            path: "zusatzfunktionen-qualifikationen/erstellen",
            name: "EmployeeFormQualificationDialogEdit",
            props: { isCreateDialog: true },
            component: () => import("../stammdaten/qualification/QualificationDialog.vue"),
            meta: {
              requiredRoles: <Permission[]>["qualification:create"],
            },
          },
        ],
      },
      {
        path: "anstellungen",
        name: "EmployeeFormEmployments",
        component: () => import("./employees/edit/EmploymentTableData.vue"),
        meta: {
          requiredRoles: <Permission[]>["employment:view"],
        },
      },
      {
        path: "anstellungen/erstellen",
        name: "EmployeeFormEmploymentCreate",
        component: () => import("./employees/edit/forms/ManageEmployment.vue"),
        meta: {
          requiredRoles: <Permission[]>["employment:create"],
        },
      },
      {
        path: "anstellungen/:anstellung",
        name: "EmployeeFormEmployment",
        component: () => import("./employees/edit/forms/ManageEmployment.vue"),
        meta: {
          requiredRoles: <Permission[]>["employment:view"],
        },
      },

      {
        path: "anstellungsunterbrechung/erstellen",
        name: "EmployeeFormEmploymentInterruptionCreate",
        component: () => import("./employees/edit/forms/ManageEmploymentInterruption.vue"),
        meta: {
          requiredRoles: <Permission[]>["employment:create"],
        },
      },
      {
        path: "anstellungsunterbrechung/:anstellung",
        name: "EmployeeFormEmploymentInterruption",
        component: () => import("./employees/edit/forms/ManageEmploymentInterruption.vue"),
        meta: {
          requiredRoles: <Permission[]>["employment:view"],
        },
      },
      {
        path: "fehlzeiten/:appointmentType",
        name: "EmployeeFormAbsenceDays",
        component: () => import("./employees/edit/AbsenceDaysTable.vue"),
        meta: {
          requiredRoles: <Permission[]>["appointment:view"],
        },
        children: [
          {
            path: "erstellen",
            name: "AbsenceDaysAppointmentDialogCreate",
            component: () => import("./appointments/AppointmentDialog.vue"),
            meta: {
              requiredRoles: <Permission[]>["appointment:create"],
            },
          },
          {
            path: "termin/:appointmentId",
            name: "AbsenceDaysAppointmentDialogEdit",
            component: () => import("./appointments/AppointmentDialog.vue"),
            meta: {
              requiredRoles: <Permission[]>["appointment:view"],
            },
          },
        ],
      },
      {
        path: "fehlzeiten/kalendar",
        name: "AbsenceDaysCalendar",
        meta: {
          requiredRoles: <Permission[]>["appointment:view"],
        },
        component: () => import("./employees/edit/AbsenceDaysCalendar.vue"),
        children: [
          {
            path: "erstellen/:appointmentType?",
            name: "CalendarAppointmentDialogCreate",
            props: { shouldResetSelection: false },
            component: () => import("./appointments/AppointmentDialog.vue"),
            meta: {
              requiredRoles: <Permission[]>["appointment:create"],
            },
          },
          {
            path: "erstellen/:appointmentType?",
            name: "CalendarAppointmentDialogCreateWithHandler",
            props: { shouldResetSelection: true },
            component: () => import("./appointments/AppointmentDialog.vue"),
            meta: {
              requiredRoles: <Permission[]>["appointment:create"],
            },
          },
          {
            path: "termin/:appointmentId",
            name: "CalendarAppointmentDialogEdit",
            component: () => import("./appointments/AppointmentDialog.vue"),
            meta: {
              requiredRoles: <Permission[]>["appointment:view"],
            },
          },
        ],
      },

      {
        path: "impfstatus",
        name: "VaccinationPage",
        component: () => import("./employees/edit/forms/VaccinationPage.vue"),
      },
    ],
  },
  {
    path: "/anstellungen",
    name: "EmploymentPage",
    component: () => import("./employments/EmploymentPage.vue"),
    meta: {
      requiredRoles: <Permission[]>["employee:list", "employment:unlock"],
    },
    children: [],
  },
  {
    path: "/termine",
    name: "AppointmentPage",
    component: () => import("./appointments/AppointmentsPage.vue"),
    meta: {
      requiredRoles: <Permission[]>["appointment:list", "appointment:unlock"],
    },
    children: [
      {
        path: ":appointmentId",
        name: "AppointmentDialogEdit",
        component: () => import("./appointments/AppointmentDialog.vue"),
        meta: {
          requiredRoles: <Permission[]>["appointment:view", "appointment:unlock"],
        },
      },
      {
        path: "erstellen",
        name: "AppointmentDialog",
        component: () => import("./appointments/AppointmentDialog.vue"),
        meta: {
          requiredRoles: <Permission[]>["appointment:create", "appointment:unlock"],
        },
      },
    ],
  },
]

export default routes
