import { type Ref, watch } from "vue"

import type { GroupedOptions, GroupOptionsItems, Option } from "@/types"
import type { Suggestion } from "@/types"

import { type Movement, type TimeRange } from "@/graphql/types"
import { i18n } from "@/i18n"

export const isDev = import.meta.env.DEV
export const isIntOrPre =
  document.location.host === "int.adebiskita.de" || document.location.host === "pre.adebiskita.de"

export const EMPTY_FIELD = "" //"\u2015"

export const dialogProps = {
  style: { width: "50vw" },
  breakpoints: { "1250px": "60vw", "990px": "85vw", "700px": "100vw" },
  pt: {
    closeButton: () => ({
      class: "hidden",
    }),
  },
  modal: true,
}

/**
 * Creates an array of option objects from an array of strings.
 * Useful when the option's name and code should be equal.
 * @param arr The string array of options
 * @returns An array of option objects
 */
export const mapOptions = (...arr: string[]) => {
  return arr.map((el) => ({
    name: el,
    code: el,
  }))
}

/**
 * Returns a copy of `array`, except if `array` contains `element`,
 * the output array will not contain `element`. Otherwise `element` is added
 * to the output array. Does not mutate the input array.
 * @param element The element to toggle.
 * @param array The new array.
 */
export function toggle<T>(element: T, array: T[]) {
  if (array.includes(element)) {
    return array.filter((el) => el !== element)
  } else {
    return [...array, element]
  }
}

/**
 * Gets the name property of an option object by its code.
 * @param code The code of the option.
 * @param options The array of options to be searched.
 * @returns The name of the options. If none is found, an empty string is returned.
 */
export function getNameByCode(code: string | number, options: Option[]) {
  return options.find((opt) => opt.code == code)?.name ?? ""
}

type NumberFormatOptions = {
  fractionDigits?: number
  maxFractionDigits?: number
  minFractionDigits?: number
  prefix?: string
  suffix?: string
  useGrouping?: boolean
}

/**
 * Formats numeric values
 * @param options formatting options
 * @returns formatter functon
 * @example
 * format({ suffix: '€', fractionDigits: 2 })(2)
 * -> '2,00€'
 */
export function format(options: NumberFormatOptions, n: number) {
  const {
    prefix = "",
    suffix = "",
    fractionDigits,
    maxFractionDigits = 3,
    minFractionDigits = 0,
    useGrouping = true,
  } = options

  return n != null
    ? prefix +
        n.toLocaleString("de-DE", {
          minimumFractionDigits: fractionDigits ?? minFractionDigits,
          maximumFractionDigits: fractionDigits ?? maxFractionDigits,
          useGrouping,
        }) +
        suffix
    : ""
}

/**
 * Restricts a value to be within a range.
 * @param min The lower bound of the range.
 * @param max The upper bound of the range.
 * @param value The value
 * @returns `min`, if value < min,
 *          `max`, if value > max,
 *          `value` otherwise
 */
export function clamp<T>(min: T, max: T, value: T): T {
  if (min > max) {
    throw new RangeError("min must be <= max")
  }

  return value < min ? min : value > max ? max : value
}

/**
 * Returns the an array of suggestion sorted alphabetically by the label property.
 * @param suggestions The array to be sorted
 */
export function sortSuggestionsAlphabetically(suggestions: Suggestion[]) {
  return suggestions.sort((a, b) => {
    if (a.label < b.label) {
      return -1
    }
    if (a.label > b.label) {
      return 1
    }
    return 0
  })
}

export function generateRandomHexString(length: number) {
  return [...Array(length)].map(() => Math.floor(Math.random() * 16).toString(16)).join("")
}

export function useWhenResult<T>(result: Ref<T | undefined>, allowNullish = false) {
  if (!allowNullish && (result.value === undefined || result.value === null))
    return Promise.resolve()

  return useOnNextResult(result, allowNullish)
}

export function useOnNextResult<T>(result: Ref<T | undefined>, allowNullish = false) {
  return new Promise<void>((resolve) => {
    const unwatch = watch(
      result,
      () => {
        if (!allowNullish && (result.value === undefined || result.value === null)) return

        unwatch()
        resolve()
      },
      { flush: "sync" }
    )
  })
}

function countryCodeAsDigits(checkDigit: string): string {
  const conversionTable: { [key: string]: string } = {
    A: "10",
    B: "11",
    C: "12",
    D: "13",
    E: "14",
    F: "15",
    G: "16",
    H: "17",
    I: "18",
    J: "19",
    K: "20",
    L: "21",
    M: "22",
    N: "23",
    O: "24",
    P: "25",
    Q: "26",
    R: "27",
    S: "28",
    T: "29",
    U: "30",
    V: "31",
    W: "32",
    X: "33",
    Y: "34",
    Z: "35",
  }

  let result = ""
  for (const char of checkDigit) {
    result += conversionTable[char] || char
  }

  return result
}

function mod97(numStr: string): number {
  let checksum = parseInt(numStr.slice(0, 2)) % 97
  for (let offset = 2; offset < numStr.length; offset += 7) {
    const fragment = checksum.toString() + numStr.slice(offset, offset + 7)
    checksum = parseInt(fragment) % 97
  }
  return checksum
}
export function processIban(input: string): string {
  if (!input.includes(" ") && !input.includes("_")) {
    return input
  }
  const cleanedInput = input.replace(/[_\s]/g, "")

  const match = cleanedInput.match(/^[A-Z]{2}\d{2}[A-Z0-9]*$/i)

  return match ? match[0] : cleanedInput
}
export function isValidIban(input: string): boolean {
  if (!input) return true
  const cleanedInput = processIban(input)

  // Check if the length is valid (general IBAN length range is 15 to 34 characters)
  if (cleanedInput.length < 15 || cleanedInput.length > 34) {
    return false
  }

  const country = cleanedInput.slice(0, 2)
  const checkDigit = cleanedInput.slice(2, 4)
  const bban = cleanedInput.slice(4)

  const alphanumeric = `${bban}${country}${checkDigit}`
  const numericString = countryCodeAsDigits(alphanumeric)

  const numeric = numericString.replace(/^0+/, "")
  return mod97(numeric) === 1
}

export function arraysEqualContent<T>(arr1: T[], arr2: T[]) {
  return arr1.length === arr2.length && arr1.every((e) => arr2.includes(e))
}

export function compareArrays<T>(arr1: T[], arr2: T[]) {
  const added = arr2.filter((item) => !arr1.includes(item))
  const removed = arr1.filter((item) => !arr2.includes(item))
  const kept = arr1.filter((item) => arr2.includes(item))
  return {
    added,
    removed,
    kept,
  }
}

export const IBAN_COUNTRY_LEN: { [key: string]: number } = {
  AD: 24,
  AE: 23,
  AL: 28,
  AT: 20,
  AZ: 28,
  BA: 20,
  BE: 16,
  BG: 22,
  BH: 22,
  BR: 29,
  BY: 28,
  CH: 21,
  CR: 22,
  CY: 28,
  CZ: 24,
  DE: 22,
  DK: 18,
  DO: 28,
  EE: 20,
  EG: 29,
  ES: 24,
  FI: 18,
  FO: 18,
  FR: 27,
  GB: 22,
  GE: 22,
  GI: 23,
  GL: 18,
  GR: 27,
  GT: 28,
  HR: 21,
  IQ: 23,
  LC: 32,
  LI: 21,
  LT: 20,
  LU: 20,
  LV: 21,
  MC: 27,
  MD: 24,
  ME: 22,
  MK: 19,
  MR: 27,
  MT: 31,
  MU: 30,
  NL: 18,
  NO: 15,
  PK: 24,
  PL: 28,
  PS: 29,
  PT: 25,
  QA: 29,
  RO: 24,
  RS: 22,
  RU: 33,
  SA: 24,
  SC: 31,
  SE: 24,
  SI: 19,
  SK: 24,
  SM: 27,
  ST: 25,
  TN: 24,
  TR: 26,
  SV: 28,
  TL: 23,
  UA: 29,
  VA: 22,
  VG: 24,
  XK: 20,
}

export function isValidBic(input: string) {
  input = input.trim()
  if (input.length !== 8 && input.length !== 11) {
    return false
  }

  const bank_code = input.substring(0, 4)
  const country_code = input.substring(4, 6)
  const location_code = input.substring(6, 8)

  if (!/^[A-Z]{4}$/.test(bank_code)) {
    return false
  }

  if (!IBAN_COUNTRY_LEN[country_code]) {
    return false
  }

  if (!/^[A-Z0-9]{2}$/.test(location_code)) {
    return false
  }

  return true
}

export function timerangesToString(timeranges: TimeRange[]) {
  return (
    timeranges
      .map((t) => (t.start && t.end ? `${t.start} - ${t.end}` : undefined))
      .filter((t) => !!t)
      .join("\r\n") || "-"
  )
}

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
type NestedRecordValue = NestedRecord | string | number | null | undefined | Array<unknown> | any
type NestedRecord = { [key: string]: NestedRecordValue }

export function getDeepProperty(obj: NestedRecord, key: string) {
  return key
    .split(".")
    .reduce<NestedRecordValue>(
      (o, k) =>
        o &&
        typeof o === "object" &&
        !Array.isArray(o) &&
        Object.prototype.hasOwnProperty.call(o, k)
          ? o?.[k]
          : undefined,
      obj
    )
}

export function movementCompare(a: Movement | undefined, b: Movement | undefined) {
  const dateAEntry = a?.entry ? new Date(a.entry).getTime() : 0
  const dateBEntry = b?.entry ? new Date(b.entry).getTime() : 0

  // First, sort by entry date in descending order
  if (dateAEntry !== dateBEntry) return dateBEntry - dateAEntry

  // If entry dates are the same, sort by exit date in descending order
  const dateAExit = a?.exit ? new Date(a.exit).getTime() : 0
  const dateBExit = b?.exit ? new Date(b.exit).getTime() : 0

  return dateBExit - dateAExit // Sort by exit date if entry dates are the same
}
type SortOrder = "asc" | "desc"

/**
 * Sorts an array of objects by a specific key lexicographically.
 * Supports ascending or descending order and case-insensitive sorting.
 *
 * @template T - The type of the objects in the array.
 * @param array - The array of objects to be sorted.
 * @param key - The key in the object to sort by.
 * @param order - The order of sorting: 'asc' for ascending (default) or 'desc' for descending.
 * @param caseInsensitive - If true, the sorting is case-insensitive (default is false).
 * @returns A new sorted array (the original array is not mutated).
 */
export function sortByKey<T>(
  array: T[],
  key: keyof T,
  order: SortOrder = "asc", // default to ascending
  caseInsensitive = false // default to case-sensitive
): T[] {
  return array.slice().sort((a, b) => {
    const keyA = a[key]
    const keyB = b[key]

    if (typeof keyA === "string" && typeof keyB === "string") {
      let valA: string = keyA
      let valB: string = keyB

      if (caseInsensitive) {
        valA = valA.toLowerCase()
        valB = valB.toLowerCase()
      }

      let comparison = valA.localeCompare(valB)

      if (order === "desc") comparison *= -1

      return comparison
    }

    return 0
  })
}

export function sortLexicographically(
  array: string[] | Option[] | GroupedOptions[] | GroupOptionsItems[] | Suggestion[],
  topKeys: (string | number)[] = []
) {
  if (!array.length) return []
  const currentLocale = i18n.global.locale.value

  let collator: Intl.Collator
  if (currentLocale) {
    collator = Intl.Collator(currentLocale)
  } else {
    collator = Intl.Collator()
  }

  // Handle `string[]`
  if (typeof array[0] === "string") {
    const sorted = [...(array as string[])].sort((a, b) => collator.compare(a, b))
    if (topKeys.length) {
      const topStrings = sorted.filter((str) => topKeys.includes(str))
      const remainingStrings = sorted.filter((str) => !topKeys.includes(str))
      return [...topStrings, ...remainingStrings]
    }
    return sorted
  }

  // Handle `Option[]`
  if ("name" in (array[0] as Option)) {
    const optionArray = array as Option[]
    const topKeyObjs = optionArray.filter((obj) => topKeys.includes(obj.code))
    if (!topKeys.length || !topKeyObjs.length) {
      return optionArray.sort((k1, k2) => collator.compare(k1.name, k2.name))
    }

    const remainingOptions = optionArray.filter(
      (obj) => !topKeyObjs.map((x) => x.code).includes(obj.code)
    )
    const sorted = remainingOptions.sort((k1, k2) => collator.compare(k1.name, k2.name))
    sorted.unshift(...topKeyObjs)
    return sorted
  }

  // Handle `GroupedOptions[]`
  if ("items" in (array[0] as GroupedOptions)) {
    const groupedArray = array as GroupedOptions[]
    return groupedArray.map((group) => {
      const sortedItems = group.items.sort((a, b) => collator.compare(a.label, b.label))
      return {
        ...group,
        items: sortedItems,
      }
    })
  }

  // Handle `GroupOptionsItems[]`
  if ("value" in (array[0] as GroupOptionsItems)) {
    const optionArray = array as GroupOptionsItems[]
    return optionArray.sort((k1, k2) => collator.compare(k1.label, k2.label))
  }

  // Handle `Suggestion[]`
  if ("id" in (array[0] as Suggestion)) {
    const optionArray = array as Suggestion[]
    const topKeyObjs = optionArray.filter((obj) => topKeys.includes(obj.id))
    if (!topKeys.length || !topKeyObjs.length) {
      return optionArray.sort((k1, k2) => collator.compare(k1.label, k2.label))
    }

    const remainingOptions = optionArray.filter(
      (obj) => !topKeyObjs.map((x) => x.id).includes(obj.id)
    )
    const sorted = remainingOptions.sort((k1, k2) => collator.compare(k1.label, k2.label))
    sorted.unshift(...topKeyObjs)
    return sorted
  }

  return array
}
export function removeFromArray(array: string[] | undefined, value: string) {
  if (!array) return
  const index = array.indexOf(value)
  if (index !== -1) array.splice(index, 1)
}
