<script setup lang="ts">
import { ref } from "vue"

import { useDialogStore } from "@/store/dialog"

defineOptions({
  inheritAttrs: false,
})

const props = defineProps({
  inputHelp: {
    type: Object,
    default: () => ({ message: "", useDialog: false }),
  },
})
const dialogStore = useDialogStore()
const op = ref()

const toggle = (event: any) => {
  op.value.toggle(event)
}

function info() {
  dialogStore.info({
    message: props.inputHelp.message,
    acceptClass: "p-button",
    icon: "pi pi-info-circle info",
    accept: () => dialogStore.close(),
  })
}
</script>

<template>
  <div v-bind="$attrs">
    <i
      :style="{ visibility: props.inputHelp.message ? 'visible' : 'hidden' }"
      class="pi pi-info-circle text-xl cursor-pointer info"
      @click="props.inputHelp.useDialog ? info() : toggle($event)"
    />
  </div>

  <Popover
    v-if="!props.inputHelp.useDialog"
    ref="op"
  >
    <div class="max-w-[25rem]">
      <p v-html="props.inputHelp.message"></p>
    </div>
  </Popover>
</template>

<style scoped>
.info {
  color: #2196f3;
}
</style>
