/* global appVersion */
import { DefaultApolloClient } from "@vue/apollo-composable"
import { createPinia } from "pinia"
import "primeicons/primeicons.css"
import PrimeVue from "primevue/config"
import Ripple from "primevue/ripple"
import ToastService from "primevue/toastservice"
import Tooltip from "primevue/tooltip"
import { createApp } from "vue"

import App from "./App.vue"
import DefaultPreset from "./assets/presets/default"
import "./assets/sass/App.scss"
import "./assets/sass/custom/style.scss"
import installDayjsPlugins from "./dayjs"
import { i18n } from "./i18n"
import primeVueLocales from "./locales/primevue/locales"
import registerComponent from "./registerComponents"
import "./registerFilters"
import { router } from "./router"
import { installYupMethods } from "./utils/formValidations"
import { apolloClient } from "./vue-apollo"

const app = createApp(App)
const pinia = createPinia()

app.use(PrimeVue, {
  locale: primeVueLocales,
  ripple: true,
  zIndex: {
    modal: 9999,
    overlay: 5000,
    menu: 5000,
    tooltip: 9000,
  },
  theme: {
    preset: DefaultPreset,
    options: {
      darkModeSelector: "[data-theme='dark']",
      cssLayer: {
        name: "primevue",
        order: "tailwind-base, primevue, tailwind-utilities",
      },
    },
  },
})

app.use(router)
app.use(pinia)
app.use(i18n)
app.use(ToastService)

app.directive("ripple", Ripple)
app.directive("tooltip", Tooltip)

app.provide(DefaultApolloClient, apolloClient)

registerComponent(app)
installDayjsPlugins()
installYupMethods()

window.appVersion = appVersion

app.mount("#app")
