import { useMutation, type MutateResult } from "@vue/apollo-composable"
import { computed, reactive, ref, watch } from "vue"
import { useRoute } from "vue-router"

import { useApi } from "./apollo/useApi"

import CreateTablePresetMutation from "@/graphql/preset/filter/CreateTableFilterPreset.gql"
import RemoveTablePresetsMutation from "@/graphql/preset/filter/RemoveTableFilterPresets.gql"
import SetDefaultTablePresetMutation from "@/graphql/preset/filter/SetDefaultTableFilterPreset.gql"
import TablePresetListQuery from "@/graphql/preset/filter/TableFilterPresetList.gql"
import UnsetDefaultTablePresetMutation from "@/graphql/preset/filter/UnsetDefaultTableFilterPreset.gql"
import UpdateTablePresetMutation from "@/graphql/preset/filter/UpdateTableFilterPreset.gql"
import {
  type MutationRootCreateTableFilterArgs,
  type MutationRootRemoveTableFiltersArgs,
  type MutationRootSetDefaultFilterArgs,
  type MutationRootUnsetDefaultFilterArgs,
  type MutationRootUpdateTableFilterArgs,
  type QueryRootTableFiltersArgs,
  type TableFilter,
  type TableFilterList,
  type TableFilterTable,
} from "@/graphql/types"
import { useSessionStore } from "@/store/session"
import { type FilterableColumn } from "@/types"

export type FilterPresetConfiguration<TData, TMappedData = TData> = ReturnType<
  typeof useFilterPreset<TData, TMappedData>
>

export type TableFilterPresetListResult = { tableFilters: TableFilterList }
export type TableFilterPresetCreateResult = { createTableFilter: TableFilter }
export type TableFilterPresetUpdateResult = { updateTableFilter: TableFilter }
export type TableFilterPresetRemoveResult = { removeTableFilters: number }
export type SetDefaultTableFilterPresetResult = { setDefaultFilter: boolean }
export type UnsetDefaultTableFilterPresetResult = { unsetDefaultFilter: boolean }

export const tableFilterPresetResultMap = {
  getList: (result: TableFilterPresetListResult) => result.tableFilters,
  getCreated: (result: TableFilterPresetCreateResult) => result.createTableFilter,
  getUpdated: (result: TableFilterPresetUpdateResult) => result.updateTableFilter,
  getRemovedCount: (result: TableFilterPresetRemoveResult) => result.removeTableFilters,
  getLinkedCount: undefined,
}

export function useFilterPreset<TData, TMappedData = TData>(
  table: TableFilterTable | undefined,
  filterableColumnDefs: FilterableColumn<TData, TMappedData>[],
  listRouteName: string | string[]
) {
  const route = useRoute()

  const sessionStore = useSessionStore()

  const listQueryVariables = computed(() => ({ table }))

  const listQueryOptions = computed(() => {
    const matchedRouteNames = route.matched.reduce<string[]>(
      (prev, next) => [...prev, <string>next.name],
      []
    )

    return {
      enabled:
        (typeof listRouteName === "string"
          ? matchedRouteNames.includes(listRouteName)
          : matchedRouteNames.some((route) => listRouteName.includes(route))) && !!table,
    }
  })

  const api = useApi<
    TableFilter,
    "tableFilters",
    TableFilterPresetListResult,
    QueryRootTableFiltersArgs,
    undefined,
    {},
    TableFilterPresetCreateResult,
    MutationRootCreateTableFilterArgs,
    TableFilterPresetUpdateResult,
    MutationRootUpdateTableFilterArgs,
    TableFilterPresetRemoveResult,
    MutationRootRemoveTableFiltersArgs
  >({
    typename: "TableFilter",
    operations: {
      list: TablePresetListQuery,
      getById: undefined,
      create: CreateTablePresetMutation,
      update: UpdateTablePresetMutation,
      remove: RemoveTablePresetsMutation,
      link: undefined,
    },
    resultMap: tableFilterPresetResultMap,
    mapRemovedIds: (variables) => variables.ids,
    listQueryVariables,
    listQueryOptions,
  })

  const activeInitialColumns = filterableColumnDefs.filter((col) => {
    const hasRequiredPermissions =
      !col?.requiredPermissions || sessionStore?.hasRoles(col.requiredPermissions)
    return hasRequiredPermissions && col.active !== false
  })

  const columns = computed(() => activeInitialColumns)

  const filterPresets = computed<TableFilter[]>(() => api.listResult?.tableFilters.items || [])

  const selectedFilterPresetId = ref<string | undefined>()
  const selectedFilterPreset = computed({
    get: () => filterPresets.value.find((p) => p.id === selectedFilterPresetId.value),
    set: (preset) => (selectedFilterPresetId.value = preset?.id),
  })

  const selectedColumnKeys = ref<Set<string>>(new Set())
  const selectedColumns = computed({
    get: () =>
      [...selectedColumnKeys.value]
        .map((key) => columns.value.find((c) => c.key === key))
        .filter((c) => !!c),

    set: (columns) => {
      selectedColumnKeys.value = new Set(columns.map((c) => c.key))
    },
  })

  selectedColumns.value = activeInitialColumns

  watch(
    selectedFilterPreset,
    (preset) => {
      selectedColumnKeys.value = new Set(
        preset
          ? preset.columns.flatMap(
              (key) => filterableColumnDefs.find((c) => c.key === key)?.key ?? []
            )
          : activeInitialColumns.map((c) => c.key)
      )
    },
    { immediate: true }
  )

  let initialDefaultSet = false

  watch(
    filterPresets,
    () => {
      if (
        selectedFilterPresetId.value &&
        !filterPresets.value?.find((p) => p.id === selectedFilterPresetId.value)
      )
        selectedFilterPresetId.value = undefined

      if ((filterPresets.value ?? []).length === 0 || initialDefaultSet) return

      const defaultColumnsPreset =
        filterPresets.value.find((p) => p.isUserDefault) ??
        filterPresets.value.find((p) => p.isGlobalDefault)

      if (defaultColumnsPreset) selectedFilterPreset.value = defaultColumnsPreset

      initialDefaultSet = true
    },
    { immediate: true }
  )

  const { mutate: setDefaultFilter, loading: setDefaultLoading } = useMutation<
    SetDefaultTableFilterPresetResult,
    MutationRootSetDefaultFilterArgs
  >(SetDefaultTablePresetMutation, {
    errorPolicy: "all",
    update: api.prepareListCacheReducer((cachedQuery, _data, variables) => {
      const list = tableFilterPresetResultMap.getList(cachedQuery)
      for (const item of list.items) item.isUserDefault = item.id === variables.id
      return cachedQuery
    }),
  })

  const { mutate: unsetDefaultFilter, loading: unsetDefaultLoading } = useMutation<
    UnsetDefaultTableFilterPresetResult,
    MutationRootUnsetDefaultFilterArgs
  >(UnsetDefaultTablePresetMutation, {
    errorPolicy: "all",
    update: api.prepareListCacheReducer((cachedQuery) => {
      const list = tableFilterPresetResultMap.getList(cachedQuery)
      for (const item of list.items) item.isUserDefault = false
      return cachedQuery
    }),
  })

  api.addRemoveReducer(api.getListFilterRemoveReducer((ids) => (item) => !ids.includes(item.id)))

  const defaultLoading = computed(() => setDefaultLoading.value || unsetDefaultLoading.value)

  async function create(
    name: string,
    columns: string[]
  ): MutateResult<TableFilterPresetCreateResult> {
    if (!table) throw Error("Table is not provided!")

    const result = await api.create({ name, table, columns })
    selectedFilterPresetId.value = result?.data?.createTableFilter.id
    return result
  }

  async function setDefault(id: string): MutateResult<SetDefaultTableFilterPresetResult> {
    if (!table) throw Error("Table is not provided!")
    return await setDefaultFilter({ table, id })
  }

  async function unsetDefault(): MutateResult<UnsetDefaultTableFilterPresetResult> {
    if (!table) throw Error("Table is not provided!")
    return await unsetDefaultFilter({ table })
  }

  function nameExists(name: string, exclude: TableFilter[] = []) {
    const excludeIds = exclude.map((p) => p.id)
    return filterPresets.value.some((p) => !excludeIds.includes(p.id) && p.name === name)
  }

  function toggleColumn(column: FilterableColumn<TData, TMappedData>) {
    if (![...selectedColumnKeys.value].some((key) => key === column.key))
      selectedColumnKeys.value.add(column.key)
    else if (
      [...selectedColumnKeys.value].filter((key) => columns.value.some((c) => c.key === key))
        .length > 1
    )
      selectedColumnKeys.value = new Set(
        [...selectedColumnKeys.value].filter((key) => key !== column.key)
      )
  }

  function setSelectedColumns(columns: FilterableColumn<TData, TMappedData>[]) {
    selectedColumns.value = columns
  }

  function setSelectedColumnsPreset(preset?: TableFilter) {
    selectedFilterPreset.value = preset
  }

  function setDefaultFilterPreset(/*preset: string*/) {
    // temporary, might be removed after filter preset rework
    //defaultFilterPreset.value = preset
  }

  return reactive({
    filter: {
      table,

      filterPresets,
      selectedFilterPresetId,
      selectedFilterPreset,
      selectedColumnKeys,
      selectedColumns,
      columns,

      nameExists,
      toggleColumn,
      setSelectedColumns,
      setSelectedColumnsPreset,
      setDefaultFilterPreset,

      api,
      defaultLoading,
      create,
      setDefault,
      unsetDefault,
    },
  })
}
