// https://primevue.org/configuration/#setlocale
// https://github.com/primefaces/primelocale

import { i18n } from "@/i18n"

const primeLocaleFiles = import.meta.glob<{
  [lang: string]: any
}>("./*.json", { eager: true })

const currentLocale = i18n.global.locale.value

const filename = Object.keys(primeLocaleFiles).find((file) => file.includes(currentLocale))
const file = filename ? primeLocaleFiles[filename] : {}
const locales = file ? file[currentLocale] : {}

export default locales
