<script setup lang="ts">
import { computed } from "vue"
import { useI18n } from "vue-i18n"

const { t: $t, te: $te } = useI18n()

interface Props {
  localePath?: string
  label?: string
  name?: string
  width?: number
  alignRight?: boolean
  disabled?: boolean
  icon?: boolean
  dialog?: boolean
  labelClass?: string
}

const props = withDefaults(defineProps<Props>(), {
  localePath: "",
  label: "",
  name: undefined,
  width: 3,
  alignRight: false,
  disabled: false,
  icon: false,
  dialog: false,
  labelClass: "",
})

const label = computed(() => {
  if ($te(`${props.localePath}.label`)) {
    return $t(`${props.localePath}.label`)
  }

  //TODO remove when all locales are conform to this schema: {"felder": {"label": "...", "help": "..."}}
  if (props.label) {
    return props.label
  }

  return ""
})
const toolTip = computed(() => {
  if ($te(`${props.localePath}.tooltip`)) {
    return $t(`${props.localePath}.tooltip`)
  }
  return ""
})
</script>

<template>
  <div
    :class="[
      `col-span-12 md:col-span-${width} my-2`,
      { 'lg:ml-2 xl:ml-4': alignRight, 'label-disabled': disabled },
    ]"
  >
    <label
      v-tooltip.bottom="toolTip"
      :class="[labelClass, toolTip && 'cursor-pointer']"
      :for="name"
      >{{ label }}</label
    >
  </div>
</template>

<style></style>
