<script setup lang="ts">
import { useField } from "vee-validate"
import { computed } from "vue"
import { useI18n } from "vue-i18n"

import { BaseInputProps } from "./BaseFormProps"
import BaseInfo from "./BaseInfo.vue"
import BaseLabel from "./BaseLabel.vue"

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(defineProps<BaseInputProps>(), {
  localePath: "",
  label: "",
  labelClass: "",
  labelWidth: 3,
  labelPosition: "pre",
  emptyLabel: false,
  width: 12,
  required: false,
  alignRight: false,
  disabled: false,
  error: "",
  infoHelpDialog: false,
  hideInfoHelp: false,
  containerClass: "",
})

const { t: $t, te: $te } = useI18n()

const { value, errorMessage } = useField<any>(() => props.name)

const error = computed(() => props.error || errorMessage.value)

const localeLabel = computed(() => {
  if ($te(`${props.localePath}.label`)) {
    return $t(`${props.localePath}.label`)
  }
  //TODO remove when all locales are conform to this schema: {"felder": {"label": "...", "help": "..."}}
  if (props.label) {
    return props.label
  }

  if (props.emptyLabel) return " " // causes the label component to be rendered even if it's empty

  return ""
})

const inputWidth = computed(() => {
  if (localeLabel.value == "" || props.labelPosition === "inline" || props.fieldType === "checkbox")
    return props.width - (isHelpIncluded.value ? 1 : 0)

  if (props.labelWidth == 12) return 12

  return props.width - props.labelWidth - (isHelpIncluded.value ? 1 : 0)
})

const MAX_CHARACTER_NUMBER = 300

const inputHelp = computed(() => {
  const localeHelpPath = `${props.localePath}.help`

  if (props.hideInfoHelp || !$te(localeHelpPath) || $t(localeHelpPath) === "")
    return { message: "" }

  return {
    message: $t(localeHelpPath),
    useDialog: props.infoHelpDialog || $t(localeHelpPath).length > MAX_CHARACTER_NUMBER,
  }
})

const isHelpIncluded = computed<boolean>(() => !!inputHelp.value.message)

const updateValue = (newValue: unknown) => {
  value.value = newValue
}

defineExpose({
  updateValue,
  value: computed(() => value.value),
})
</script>

<template>
  <BaseLabel
    v-if="localeLabel && labelPosition === 'pre'"
    :label-class="labelClass"
    :name="name"
    :locale-path="localePath"
    :label="localeLabel"
    :width="labelWidth"
    :align-right="alignRight"
    :disabled="disabled"
  />

  <div
    :class="[
      `col-span-${!isHelpIncluded ? 12 : 11} md:col-span-${inputWidth}`,
      { required: required, 'md:m-auto': fieldType === 'switch' },
      containerClass,
    ]"
  >
    <div class="flex flex-col gap-1 justify-center self-start">
      <template v-if="fieldType === 'checkbox'">
        <div class="flex gap-1">
          <slot
            :value="value"
            :update="updateValue"
            :error="!!error"
          />
        </div>
      </template>
      <template v-else
        ><slot
          :value="value"
          :update="updateValue"
          :error="!!error"
        />
      </template>

      <small
        v-if="!!error"
        class="input-w-error"
        v-html="error"
      ></small>
    </div>
  </div>

  <BaseInfo
    v-if="isHelpIncluded"
    :input-help="inputHelp"
    class="col-span-1 text-center mt-2"
  />

  <BaseLabel
    v-if="localeLabel && labelPosition === 'post'"
    :class="[labelClass, { 'label-disabled': disabled }]"
    :name="name"
    :locale-path="localePath"
    :label="localeLabel"
    :width="labelWidth"
    :align-right="alignRight"
  />
</template>

<style lang="scss" scoped></style>
