import dayjs, { type Dayjs } from "dayjs"
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"
import { useI18n } from "vue-i18n"

import { firstDayOfKindergartenYear, calculateAge, Month } from "./calendar"

import {
  BookingDaysEnum,
  BookingTimeCategory,
  type CreateBookingInput,
  type BookingTypeObjectInput,
  type Booking,
  BookingTypeEnum,
  type BookingTypeObject,
  type BookingTimes,
  type TimeSpan,
} from "@/graphql/types"

dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)

// TODO: replace by GraphQL data model
type Buchung = {
  eingliederungshilfe: boolean
  krippenkind: boolean
  schulkind: boolean
  integration: boolean
  migration: boolean
  keinunter3: boolean
  fixunter3: boolean
}

export enum Gewichtung {
  REGEL = 1,
  SCHULKIND = 2,
  MIGRATION = 3,
  UNTER3 = 4,
  BEHINDERT = 5,
}

type ZeitkategorieFormat = "S" | "K" | "M" | "L" | "W" | "N"

/**
 * Liefert zur übergebenen Gewichtung den Faktor, mit dem die Buchungszeit multipliziert wird.
 * @param gewichtung Gewichtung (1=Regel, 2=Schulkinder , 3=Migration, 4=Unter Drei, 5=behindert)
 * @param behindertFaktor optional, höherer Gewichtungsfaktor für behinderte Kinder
 * @returns Entsprechender Faktor für Gewichtung
 */
export function getGewichtungsFaktor(gewichtung: Gewichtung, behindertFaktor = 0): number {
  switch (gewichtung) {
    case Gewichtung.BEHINDERT:
      return Math.max(4.5, behindertFaktor)
    case Gewichtung.UNTER3:
      return 2.0
    case Gewichtung.MIGRATION:
      return 1.3
    case Gewichtung.SCHULKIND:
      return 1.2
    default:
      return 1.0
  }
}

/**
 * Liefert den internen Gewichtungsfaktor entsprechend der übergebenen Merkmale
 * (1=Regel, 2=Schulkind, 3=Migration, 4=UnterDrei, 5=Behindert)
 * @param refdate Referenzdatum
 * @param gebdatum Geburtsdatum des Kindes
 * @param buchung Buchungsobjekt
 * @returns Interner Gewichtungsfaktor
 */
export function getInternalGewichtungsFaktor(
  refdate: Dayjs,
  gebdatum: Dayjs,
  buchung: Buchung
): Gewichtung {
  const gewichtungsAlter = getGewichtungsAlter(
    refdate,
    gebdatum,
    buchung.krippenkind,
    buchung.fixunter3
  )

  if (buchung.eingliederungshilfe) {
    return Gewichtung.BEHINDERT
  } else if (gewichtungsAlter < 3 && (!buchung.keinunter3 || buchung.krippenkind)) {
    // Sofern Gewichtung 'Unter 3' nicht ignoriert werden soll
    return Gewichtung.UNTER3
  } else if (buchung.migration) {
    return Gewichtung.MIGRATION
  } else if (buchung.schulkind) {
    return Gewichtung.SCHULKIND
  } else {
    return Gewichtung.REGEL
  }
}

/**
 * Liefert das für die Gewichtung des Kindes relevante Alter
 * @param refDate Referenzdatum
 * @param gebdatum Geburtsdatum des Kindes
 * @param krippenkind Ist das Kind ein Krippenkind?
 * @param fixunter3 Ist eine Ausnahme zur Förderung mit Faktor 2,0 gesetzt?
 * @returns Für die Gewichtung relevantes Alter
 */
export function getGewichtungsAlter(
  refdate: Dayjs,
  gebdatum: Dayjs,
  krippenkind: boolean,
  fixunter3: boolean
) {
  if (!gebdatum) {
    throw new Error("Kein Geburtsdatum gesetzt")
  }

  // TODO
  const mandantIsKrippe = false

  // Bei Krippenkindern: Prüfen, ob das Kind am Beginn des Kitajahres unter drei war
  // Nur wenn das Kind erst im zweiten Monat 3 Jahre alt wurde, gilt der Faktor 2.0
  // Wenn das Kind im ersten Monat bereits 3 Jahre wird, greift das Monatsprinzip
  // Ansonsten: Alter zum Ende des Referenzmonats prüfen
  const beginOfKigaYear = firstDayOfKindergartenYear(refdate)

  const stichtag = krippenkind ? beginOfKigaYear.endOf("month") : refdate.endOf("month")

  const alterZumStichtag = calculateAge(gebdatum, stichtag)

  // Ausnahme: Wenn Kind im September drei wird und Ausnahme gesetzt, ist das Alter 2
  if (
    fixunter3 &&
    alterZumStichtag == 3 &&
    stichtag.month() === Month.SEPTEMBER &&
    mandantIsKrippe
  ) {
    return calculateAge(gebdatum, beginOfKigaYear.subtract(1, "day"))
  } else {
    return alterZumStichtag
  }
}

export function determineTimeCategory(totalHours: number): BookingTimeCategory {
  if (totalHours > 12) {
    return BookingTimeCategory.Twelve
  } else if (totalHours > 11) {
    return BookingTimeCategory.Eleven
  } else if (totalHours > 10) {
    return BookingTimeCategory.Ten
  } else if (totalHours > 9) {
    return BookingTimeCategory.Nine
  } else if (totalHours > 8) {
    return BookingTimeCategory.Eight
  } else if (totalHours > 7) {
    return BookingTimeCategory.Seven
  } else if (totalHours > 6) {
    return BookingTimeCategory.Six
  } else if (totalHours > 5) {
    return BookingTimeCategory.Five
  } else if (totalHours > 4) {
    return BookingTimeCategory.Four
  } else if (totalHours > 3) {
    return BookingTimeCategory.Three
  } else if (totalHours > 2) {
    return BookingTimeCategory.Two
  } else if (totalHours > 1) {
    return BookingTimeCategory.One
  } else {
    return BookingTimeCategory.Zero
  }
}

export function mapCategoryToTimeFactor(timeCategory: BookingTimeCategory) {
  switch (timeCategory) {
    case BookingTimeCategory.One:
      return 0.5
    case BookingTimeCategory.Two:
      return 0.75
    case BookingTimeCategory.Three:
      return 1.0
    case BookingTimeCategory.Four:
      return 1.25
    case BookingTimeCategory.Five:
      return 1.5
    case BookingTimeCategory.Six:
      return 1.75
    case BookingTimeCategory.Seven:
      return 2.0
    case BookingTimeCategory.Eight:
      return 2.25
    case BookingTimeCategory.Nine:
    case BookingTimeCategory.Ten:
    case BookingTimeCategory.Eleven:
    case BookingTimeCategory.Twelve:
      return 2.5
    default:
      return 0
  }
}

export function calculateTimeFactor(totalHours: number) {
  const timeCategory = determineTimeCategory(totalHours)
  return mapCategoryToTimeFactor(timeCategory)
}

/**
 * Gibt zur übergebenen Belegungszeitenkategorie die Bezeichnung in dem gewünschten Format zurück
 * @param buzeitkat ID-Nr der Belegungszeitkategorie
 * @param format Format der Belegungszeitenkategorie, default: L (lang)
 *
 * Formate der Belegungszeitkategorie:
 *
 *    format="S", small, ">3-4"
 *
 *    format="K", kibig, ">3-4 Std."
 *
 *    format="M", medium, ">3 bis 4"
 *
 *    format="L", large, "über 3 bis incl. 4 Stunden"
 *
 *    format="W", kibigweb, "bis 4 Stunden"
 *
 * @param maxHours Größte Stundenkategorie (default: 12; 9 entspr. BayKiBig-Kategorien)
 * @returns Belegungszeitkategorie als String
 */
export function getZeitkategorieBez(
  buzeitkat: number,
  format: ZeitkategorieFormat = "L",
  maxHours = 12
) {
  if (!Number.isInteger(buzeitkat)) {
    throw new Error("Buchungszeitkategorie must be an integer")
  }

  const { t } = useI18n()

  if (buzeitkat == 0) {
    return ""
  } else if (buzeitkat >= maxHours) {
    return t(`zeitkategorie.max.${format}`, [maxHours])
  } else {
    return t(`zeitkategorie.normal.${format}`, [buzeitkat, buzeitkat + 1])
  }
}

export function getBookingType(
  type: BookingTypeEnum,
  data: {
    communeId?: string
    slotSplitting: boolean
    percent?: number
    bookingDaysPolicy?: BookingDaysEnum
    bookingDaysCount?: number
  }
): BookingTypeObjectInput | undefined {
  const {
    communeId,
    slotSplitting = false,
    percent = 0,
    bookingDaysPolicy = BookingDaysEnum.ManuallySet,
    bookingDaysCount = 0,
  } = data
  switch (type) {
    case BookingTypeEnum.Standard:
      return {
        standard: {
          communeId,
          slotSplitting,
        },
      }
    case BookingTypeEnum.Alternative_1:
      return {
        alternative1: {
          percent: percent,
        },
      }
    case BookingTypeEnum.Alternative_2:
      return {
        alternative2: {
          percent: percent,
        },
      }
    case BookingTypeEnum.ShortTime: {
      return {
        shortTime: {
          bookingDays: getBookingDaysCount(bookingDaysPolicy, bookingDaysCount).bookingDays,
        },
      }
    }
    case BookingTypeEnum.Vacation_1:
      return {
        vacation1: {
          bookingDays: getBookingDaysCount(bookingDaysPolicy, bookingDaysCount).bookingDays,
        },
      }
    case BookingTypeEnum.Vacation_2:
      return {
        vacation2: {
          bookingDays: getBookingDaysCount(bookingDaysPolicy, bookingDaysCount).bookingDays,
        },
      }
    case BookingTypeEnum.Vacation_3:
      return {
        vacation3: {
          bookingDays: getBookingDaysCount(bookingDaysPolicy, bookingDaysCount).bookingDays,
        },
      }
    default:
      return
  }
}

export function getBookingTypeEnum(bookingObject?: BookingTypeObjectInput): BookingTypeEnum {
  if (bookingObject?.standard) {
    return BookingTypeEnum.Standard
  } else if (bookingObject?.alternative1) {
    return BookingTypeEnum.Alternative_1
  } else if (bookingObject?.alternative2) {
    return BookingTypeEnum.Alternative_2
  } else if (bookingObject?.shortTime) {
    return BookingTypeEnum.ShortTime
  } else if (bookingObject?.vacation1) {
    return BookingTypeEnum.Vacation_1
  } else if (bookingObject?.vacation2) {
    return BookingTypeEnum.Vacation_2
  } else if (bookingObject?.vacation3) {
    return BookingTypeEnum.Vacation_3
  } else {
    return BookingTypeEnum.Standard
  }
}

export function getBookingTypeEnumFromObject(bookingObject?: BookingTypeObject): BookingTypeEnum {
  const type = bookingObject ? Object.keys(bookingObject)[0] : null

  switch (type) {
    case "standard":
      return BookingTypeEnum.Standard
    case "shortTime":
      return BookingTypeEnum.ShortTime
    case "alternative1":
      return BookingTypeEnum.Alternative_1
    case "alternative2":
      return BookingTypeEnum.Alternative_2
    case "vacation1":
      return BookingTypeEnum.Vacation_1
    case "vacation2":
      return BookingTypeEnum.Vacation_2
    case "vacation3":
      return BookingTypeEnum.Vacation_3
    default:
      return BookingTypeEnum.Standard
  }
}

export function getBookingTypeLabel(booking: Booking | CreateBookingInput) {
  if (!booking) return BookingTypeEnum.Standard
  if ("type" in booking) {
    return booking.type
  } else {
    return getBookingTypeEnum(booking.typeObject)
  }
}

export function getBookingDaysCount(policy: BookingDaysEnum, bookingDaysCount: number) {
  switch (policy) {
    case BookingDaysEnum.AccordingToCalendar:
      return {
        bookingDays: {
          accordingToCalendar: {
            ignoreThis: 0,
          },
        },
        value: 0,
        disabled: true,
      }

    case BookingDaysEnum.AccordingToOpeningDays:
      //TODO get day count from calendar page
      return {
        bookingDays: {
          accordingToOpeningDays: {
            ignoreThis: 0,
          },
        },
        value: 0,
        disabled: true,
      }

    case BookingDaysEnum.ManuallySet: {
      return {
        bookingDays: {
          manuallySet: {
            days: bookingDaysCount,
          },
        },
        value: bookingDaysCount,
        disabled: false,
      }
    }
  }
}

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const getTypeObjectProperty = (typeObject: Record<string, any>, property: string) => {
  if (typeObject && property in typeObject) {
    return typeObject[property]
  }
  return undefined
}

export const extractBookingDaysAndPolicy = (
  typeObject:
    | BookingTypeObject
    | BookingTypeObjectInput
    | undefined /*ShortTime | Vacation1 | Vacation2 | Vacation3*/
): { policy: BookingDaysEnum; days?: number } => {
  if (!typeObject) {
    return { policy: BookingDaysEnum.AccordingToOpeningDays, days: undefined }
  }

  if ("shortTime" in typeObject) {
    if (typeObject.shortTime?.bookingDays?.accordingToCalendar) {
      return { policy: BookingDaysEnum.AccordingToCalendar }
    }
    if (typeObject.shortTime?.bookingDays?.accordingToOpeningDays) {
      return { policy: BookingDaysEnum.AccordingToOpeningDays }
    }
    if (typeObject.shortTime?.bookingDays?.manuallySet) {
      return {
        policy: BookingDaysEnum.ManuallySet,
        days: typeObject.shortTime.bookingDays.manuallySet.days,
      }
    }
  } else if ("vacation1" in typeObject) {
    if (typeObject.vacation1?.bookingDays?.accordingToCalendar) {
      return { policy: BookingDaysEnum.AccordingToCalendar }
    }
    if (typeObject.vacation1?.bookingDays?.accordingToOpeningDays) {
      return { policy: BookingDaysEnum.AccordingToOpeningDays }
    }
    if (typeObject.vacation1?.bookingDays?.manuallySet) {
      return {
        policy: BookingDaysEnum.ManuallySet,
        days: typeObject.vacation1.bookingDays.manuallySet.days,
      }
    }
  } else if ("vacation2" in typeObject) {
    if (typeObject.vacation2?.bookingDays?.accordingToCalendar) {
      return { policy: BookingDaysEnum.AccordingToCalendar }
    }
    if (typeObject.vacation2?.bookingDays?.accordingToOpeningDays) {
      return { policy: BookingDaysEnum.AccordingToOpeningDays }
    }
    if (typeObject.vacation2?.bookingDays?.manuallySet) {
      return {
        policy: BookingDaysEnum.ManuallySet,
        days: typeObject.vacation2.bookingDays.manuallySet.days,
      }
    }
  } else if ("vacation3" in typeObject) {
    if (typeObject.vacation3?.bookingDays?.accordingToCalendar) {
      return { policy: BookingDaysEnum.AccordingToCalendar }
    }
    if (typeObject.vacation3?.bookingDays?.accordingToOpeningDays) {
      return { policy: BookingDaysEnum.AccordingToOpeningDays }
    }
    if (typeObject.vacation3?.bookingDays?.manuallySet) {
      return {
        policy: BookingDaysEnum.ManuallySet,
        days: typeObject.vacation3.bookingDays.manuallySet.days,
      }
    }
  } else if ("bookingDays" in typeObject) {
    return {
      policy: typeObject.bookingDaysEnum,
      ...("days" in typeObject.bookingDays ? { days: typeObject.bookingDays.days } : {}),
    }
  }
  return { policy: BookingDaysEnum.AccordingToOpeningDays, days: undefined }
}

export const extractBookingType = (
  typeObject: BookingTypeObjectInput | undefined
): BookingTypeEnum | undefined => {
  if (!typeObject) return undefined

  if ("standard" in typeObject) return BookingTypeEnum.Standard
  if ("shortTime" in typeObject) return BookingTypeEnum.ShortTime
  if ("alternative1" in typeObject) return BookingTypeEnum.Alternative_1
  if ("alternative2" in typeObject) return BookingTypeEnum.Alternative_2
  if ("vacation1" in typeObject) return BookingTypeEnum.Vacation_1
  if ("vacation2" in typeObject) return BookingTypeEnum.Vacation_2
  if ("vacation3" in typeObject) return BookingTypeEnum.Vacation_3
}

export const BookingTypePriority: { [key in BookingTypeEnum]: number } = {
  [BookingTypeEnum.Standard]: 1,
  [BookingTypeEnum.ShortTime]: 2,
  [BookingTypeEnum.Alternative_1]: 3,
  [BookingTypeEnum.Alternative_2]: 3,
  [BookingTypeEnum.Vacation_1]: 3,
  [BookingTypeEnum.Vacation_2]: 3,
  [BookingTypeEnum.Vacation_3]: 3,
} as const

export function sortBookings<T extends { typeObject?: BookingTypeObjectInput }>(a: T, b: T) {
  const atype = extractBookingType(a.typeObject)
  const btype = extractBookingType(b.typeObject)
  if (atype && btype) {
    return BookingTypePriority[atype] - BookingTypePriority[btype]
  }
  return 0
}

export function doesOverlapWithBookings(
  bookings: { type: BookingTypeEnum; from: string; to: string }[],
  type: BookingTypeEnum,
  from: string,
  to: string
): boolean {
  return bookings.some((booking) => {
    return (
      booking.type === type &&
      dayjs(booking.from).isSameOrBefore(dayjs(to)) &&
      dayjs(booking.to).isSameOrAfter(dayjs(from))
    )
  })
}

export function doesOverlapWithAnyBooking(
  bookings: { type: BookingTypeEnum; from: string; to: string }[],
  from: string,
  to: string
): boolean {
  return bookings.some((booking) => {
    return (
      dayjs(booking.from).isSameOrBefore(dayjs(to)) && dayjs(booking.to).isSameOrAfter(dayjs(from))
    )
  })
}

type Parent = {
  to?: string
  from?: string
  bookingTypeLabel?: string
}
export function overlappingValidation<
  T extends { to: string; from: string; type: BookingTypeEnum },
>(
  parent: Parent,
  processedBookings: T[],
  originalFrom?: string,
  originalTo?: string
): { isValid: boolean; message?: string } {
  const { bookingTypeLabel, from, to } = parent

  if (!processedBookings.length || !bookingTypeLabel || !from || !to) {
    return { isValid: true }
  }

  switch (bookingTypeLabel) {
    case BookingTypeEnum.Standard: {
      const containedBookings = processedBookings.filter(
        (booking) =>
          booking.type !== BookingTypeEnum.Standard &&
          dayjs(booking.from).isSameOrAfter(dayjs(originalFrom)) &&
          dayjs(booking.to).isSameOrBefore(dayjs(originalTo))
      )
      const allBookingsStillContained = containedBookings.every(
        (booking) =>
          dayjs(booking.from).isSameOrAfter(dayjs(from)) &&
          dayjs(booking.to).isSameOrBefore(dayjs(to))
      )

      if (!allBookingsStillContained) {
        return { isValid: false, message: "standard_must_contain_existing_bookings" }
      }

      // Check if Standard booking overlaps with a ShortTime booking
      const overlapsWithShortTime = doesOverlapWithBookings(
        processedBookings,
        BookingTypeEnum.ShortTime,
        from,
        to
      )

      if (overlapsWithShortTime) {
        return { isValid: false, message: "booking_overlaps_short_time" }
      }

      const overlapsWithStandard = doesOverlapWithBookings(
        processedBookings,
        BookingTypeEnum.Standard,
        from,
        to
      )

      if (overlapsWithStandard) {
        return { isValid: false, message: "booking_overlaps_standard_time" }
      }
      break
    }

    case BookingTypeEnum.ShortTime: {
      const overlapsWithAny = doesOverlapWithAnyBooking(processedBookings, from, to)
      if (overlapsWithAny) {
        return { isValid: false, message: "booking_no_overlap_allowed" }
      }
      break
    }

    case BookingTypeEnum.Alternative_1:
    case BookingTypeEnum.Alternative_2:
    case BookingTypeEnum.Vacation_1:
    case BookingTypeEnum.Vacation_2:
    case BookingTypeEnum.Vacation_3: {
      // Ensure a Standard booking fully contains this booking

      const overlapsWithShortTime = doesOverlapWithBookings(
        processedBookings,
        BookingTypeEnum.ShortTime,
        from,
        to
      )

      if (overlapsWithShortTime) {
        return { isValid: false, message: "booking_overlaps_short_time" }
      }

      const standardBookings = processedBookings.filter((b) => b.type === BookingTypeEnum.Standard)
      const mergedRanges = mergeDateRanges([...standardBookings])

      const variantBookingDateRangeIsValid = isOverlapping(mergedRanges, from, to)

      if (!variantBookingDateRangeIsValid) {
        return { isValid: false, message: "booking_must_be_fully_inside_standard" }
      }
      break
    }

    default:
      return { isValid: true }
  }

  return { isValid: true }
}

export function filterBookingsByContract(
  contractFrom: string,
  contractTo: string | null,
  bookingFrom: string,
  bookingTo: string | null
) {
  const contractFromDate = dayjs(contractFrom)
  const contractToDate = contractTo ? dayjs(contractTo) : null
  const bookingFromDate = dayjs(bookingFrom)
  const bookingToDate = bookingTo ? dayjs(bookingTo) : null

  if (!contractToDate) {
    if (!bookingToDate) {
      return bookingFromDate.isSameOrAfter(contractFromDate)
    }
    return bookingToDate.isSameOrAfter(contractFromDate)
  }

  if (!bookingToDate) {
    return bookingFromDate.isSameOrBefore(contractToDate)
  }

  return (
    bookingFromDate.isSameOrBefore(contractToDate) && bookingToDate.isSameOrAfter(contractFromDate)
  )
}

export function calculateTotalHours(times: BookingTimes): number {
  const calculateHours = (span: TimeSpan | null | undefined): number => {
    if (!span) return 0

    const [fromHours, fromMinutes] = span.from.split(":").map(Number)
    const [toHours, toMinutes] = span.to.split(":").map(Number)

    const fromInMinutes = fromHours * 60 + fromMinutes
    const toInMinutes = toHours * 60 + toMinutes

    return (toInMinutes - fromInMinutes) / 60
  }

  let totalHours = 0
  const { comments: _c, __typename, ...weekdays } = times

  for (const day of Object.values(weekdays)) {
    if (day) {
      totalHours += calculateHours(day.am)
      totalHours += calculateHours(day.pm)
    }
  }

  return totalHours
}

function mergeDateRanges<T extends { from: string; to?: string }>(ranges: T[]): T[] {
  ranges.forEach((range) => {
    if (!range.to) {
      range.to = "9999-01-01"
    }
  })

  ranges.sort((a, b) => dayjs(a.from).diff(dayjs(b.from)))

  const mergedRanges: T[] = []
  let currentRange = { ...ranges[0] }

  for (let i = 1; i < ranges.length; i++) {
    const nextRange = ranges[i]

    if (dayjs(currentRange.to).add(1, "day").isSameOrAfter(dayjs(nextRange.from))) {
      currentRange.to = dayjs(currentRange.to).isAfter(dayjs(nextRange.to))
        ? currentRange.to
        : nextRange.to
    } else {
      mergedRanges.push(currentRange)
      currentRange = { ...nextRange }
    }
  }

  mergedRanges.push(currentRange)

  return mergedRanges
}

function isOverlapping<T extends { from: string; to?: string }>(
  mergedRanges: T[],
  from: string,
  to?: string
): boolean {
  const futureDate = dayjs().add(1000, "year")

  const fromDate = dayjs(from)
  const toDate = to ? dayjs(to) : futureDate

  for (const range of mergedRanges) {
    const rangeFrom = dayjs(range.from)
    const rangeTo = range.to ? dayjs(range.to) : futureDate

    if (fromDate.isSameOrAfter(rangeFrom) && toDate.isSameOrBefore(rangeTo)) {
      return true
    }
  }

  return false
}
