import { getOptions } from "./composables/useDropdown"

export enum Salutation {
  f = "f",
  m = "m",
}

export enum Title {
  dr = "dr",
  prof = "prof",
  profdr = "profdr",
}

export enum Country {
  DEU = "DEU",
  AUT = "AUT",
  BEL = "BEL",
  CZE = "CZE",
  DNK = "DNK",
  FRA = "FRA",
  LUX = "LUX",
  NLD = "NLD",
  POL = "POL",
  CHE = "CHE",
}
export enum Gender {
  w = "w",
  m = "m",
  d = "d",
  a = "a",
}
export enum Region {
  SW = "SW",
  OP = "OP",
  PF = "PF",
  OB = "OB",
  NB = "NB",
  MF = "MF",
}
export enum Denomination {
  rk = "rk",
  ev = "ev",
  ak = "ak",
  av = "av",
  ac = "ac",
  bd = "bd",
  cs = "cs",
  rf = "rf",
  ef = "ef",
  lt = "lt",
  fr = "fr",
  go = "go",
  hi = "hi",
  is = "is",
  k = "k",
  mu = "mu",
  ne = "ne",
  oa = "oa",
  ru = "ru",
  ro = "ro",
  sc = "sc",
  ok = "ok",
  uk = "uk",
  uo = "uo",
  vd = "vd",
  z = "z",
}

export const Language = {
  de: "de",
  sq: "sq",
  am: "am",
  ar: "ar",
  az: "az",
  "as-IN": "as-IN",
  eu: "eu",
  bn: "bn",
  "bn-BD": "bn-BD",
  "bn-IN": "bn-IN",
  bs: "bs",
  "bs-BA": "bs-BA",
  br: "br",
  bg: "bg",
  "zh-TW": "zh-TW",
  "zh-CN": "zh-CN",
  da: "da",
  dz: "dz",
  en: "en",
  "en-GB": "en-GB",
  "en-ZA": "en-ZA",
  "en-US": "en-US",
  eo: "eo",
  et: "et",
  fi: "fi",
  fr: "fr",
  "fr-CA": "fr-CA",
  gd: "gd",
  gl: "gl",
  el: "el",
  "gu-IN": "gu-IN",
  he: "he",
  "hi-IN": "hi-IN",
  ga: "ga",
  it: "it",
  ja: "ja",
  "kn-IN": "kn-IN",
  ca: "ca",
  km: "km",
  rw: "rw",
}

export const Citizenship = {
  "001": "001",
  "287": "287",
  "274": "274",
  "225": "225",
  "423": "423",
  "121": "121",
  "221": "221",
  "368": "368",
  "123": "123",
  "223": "223",
  "320": "320",
  "323": "323",
  "422": "422",
  "425": "425",
  "523": "523",
  "324": "324",
  "424": "424",
  "460": "460",
  "322": "322",
  "124": "124",
  "330": "330",
  "229": "229",
  "426": "426",
  "326": "326",
  "122": "122",
  "227": "227",
  "327": "327",
  "168": "168",
  "295": "295",
  "395": "395",
  "495": "495",
  "595": "595",
  "195": "195",
  "125": "125",
  "258": "258",
  "291": "291",
  "332": "332",
  "479": "479",
  "334": "334",
  "126": "126",
  "469": "469",
  "333": "333",
  "335": "335",
  "230": "230",
  "336": "336",
  "224": "224",
  "127": "127",
  "199": "199",
  "526": "526",
  "128": "128",
  "129": "129",
  "236": "236",
  "237": "237",
  "430": "430",
  "238": "238",
  "340": "340",
  "134": "134",
  "345": "345",
  "328": "328",
  "259": "259",
  "261": "261",
  "346": "346",
  "347": "347",
  "436": "436",
  "437": "437",
  "438": "438",
  "439": "439",
  "135": "135",
  "136": "136",
  "441": "441",
  "137": "137",
  "231": "231",
  "355": "355",
  "442": "442",
  "421": "421",
  "445": "445",
  "138": "138",
  "262": "262",
  "446": "446",
  "348": "348",
  "242": "242",
  "444": "444",
  "447": "447",
  "243": "243",
  "450": "450",
  "530": "530",
  "349": "349",
  "244": "244",
  "245": "245",
  "434": "434",
  "467": "467",
  "150": "150",
  "130": "130",
  "351": "351",
  "448": "448",
  "449": "449",
  "226": "226",
  "139": "139",
  "451": "451",
  "247": "247",
  "248": "248",
  "141": "141",
  "142": "142",
  "366": "366",
  "143": "143",
  "249": "249",
  "256": "256",
  "482": "482",
  "454": "454",
  "251": "251",
  "145": "145",
  "252": "252",
  "239": "239",
  "253": "253",
  "144": "144",
  "353": "353",
  "146": "146",
  "147": "147",
  "457": "457",
  "140": "140",
  "254": "254",
  "427": "427",
  "267": "267",
  "531": "531",
  "458": "458",
  "536": "536",
  "354": "354",
  "148": "148",
  "232": "232",
  "255": "255",
  "149": "149",
  "151": "151",
  "999": "999",
  "456": "456",
  "461": "461",
  "357": "357",
  "538": "538",
  "359": "359",
  "361": "361",
  "462": "462",
  "152": "152",
  "153": "153",
  "265": "265",
  "154": "154",
  "160": "160",
  "524": "524",
  "337": "337",
  "257": "257",
  "543": "543",
  "156": "156",
  "268": "268",
  "472": "472",
  "157": "157",
  "158": "158",
  "269": "269",
  "170": "170",
  "132": "132",
  "271": "271",
  "272": "272",
  "233": "233",
  "474": "474",
  "155": "155",
  "131": "131",
  "273": "273",
  "299": "299",
  "399": "399",
  "499": "499",
  "599": "599",
  "159": "159",
  "161": "161",
  "431": "431",
  "997": "997",
  "263": "263",
  "276": "276",
  "364": "364",
  "281": "281",
  "475": "475",
  "470": "470",
  "465": "465",
  "282": "282",
  "476": "476",
  "283": "283",
  "541": "541",
  "284": "284",
  "162": "162",
  "164": "164",
  "285": "285",
  "163": "163",
  "471": "471",
  "540": "540",
  "286": "286",
  "166": "166",
  "165": "165",
  "998": "998",
  "365": "365",
  "477": "477",
  "532": "532",
  "167": "167",
  "367": "367",
  "432": "432",
  "369": "369",
  "544": "544",
  "545": "545",
  "370": "370",
  "371": "371",
  "169": "169",
  "246": "246",
  "289": "289",
  "181": "181",
}

export const salutationOptions = getOptions(Salutation, "salutations")
export const titleOptions = getOptions(Title, "titles")
export const countryOptions = getOptions(Country, "countries_code")
export const genderOptions = getOptions(Gender, "genders")
export const regionOptions = getOptions(Region, "regions")
export const denominationOptions = getOptions(Denomination, "denominations")
export const citizenshipOptions = getOptions(Citizenship, "citizenships")
export const languageOptions = getOptions(Language, "languages")
