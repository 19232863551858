import { useMutation, type MutateResult } from "@vue/apollo-composable"
import { computed, reactive, ref, watch } from "vue"
import { useRoute } from "vue-router"

import { useApi } from "./apollo/useApi"

import CreateTablePresetMutation from "@/graphql/preset/CreateTablePreset.gql"
import RemoveTablePresetsMutation from "@/graphql/preset/RemoveTablePresets.gql"
import SetDefaultTablePresetMutation from "@/graphql/preset/SetDefaultTablePreset.gql"
import TablePresetListQuery from "@/graphql/preset/TablePresetList.gql"
import UnsetDefaultTablePresetMutation from "@/graphql/preset/UnsetDefaultTablePreset.gql"
import UpdateTablePresetMutation from "@/graphql/preset/UpdateTablePreset.gql"
import {
  type MutationRootCreateTablePresetArgs,
  type MutationRootRemoveTablePresetsArgs,
  type MutationRootSetDefaultPresetArgs,
  type MutationRootUnsetDefaultPresetArgs,
  type MutationRootUpdateTablePresetArgs,
  type QueryRootTablePresetsArgs,
  type Table,
  type TablePreset,
  type TablePresetList,
} from "@/graphql/types"
import { useSessionStore } from "@/store/session"
import { type Column } from "@/types"
import { generateGQL } from "@/utils/gqlbuilder"

export type ColumnPresetConfiguration<TData, TMappedData = TData> = ReturnType<
  typeof useColumnPreset<TData, TMappedData>
>

export type TablePresetListResult = { tablePresets: TablePresetList }
export type TablePresetCreateResult = { createTablePreset: TablePreset }
export type TablePresetUpdateResult = { updateTablePreset: TablePreset }
export type TablePresetRemoveResult = { removeTablePresets: number }
export type SetDefaultTablePresetResult = { setDefaultPreset: boolean }
export type UnsetDefaultTablePresetResult = { unsetDefaultPreset: boolean }

export const tablePresetResultMap = {
  getList: (result: TablePresetListResult) => result.tablePresets,
  getCreated: (result: TablePresetCreateResult) => result.createTablePreset,
  getUpdated: (result: TablePresetUpdateResult) => result.updateTablePreset,
  getRemovedCount: (result: TablePresetRemoveResult) => result.removeTablePresets,
  getLinkedCount: undefined,
}

export function useColumnPreset<TData, TMappedData = TData>(
  table: Table | undefined,
  columnDefs: Column<TData, TMappedData>[],
  listRouteName: string | string[]
) {
  const route = useRoute()
  const sessionStore = useSessionStore()

  const listQueryVariables = computed(() => ({ table }))

  const listQueryOptions = computed(() => {
    const matchedRouteNames = route.matched.reduce<string[]>(
      (prev, next) => [...prev, <string>next.name],
      []
    )

    return {
      enabled:
        typeof listRouteName === "string"
          ? matchedRouteNames.includes(listRouteName)
          : matchedRouteNames.some((route) => listRouteName.includes(route)),
    }
  })

  const api = useApi<
    TablePreset,
    "tablePresets",
    TablePresetListResult,
    QueryRootTablePresetsArgs,
    undefined,
    {},
    TablePresetCreateResult,
    MutationRootCreateTablePresetArgs,
    TablePresetUpdateResult,
    MutationRootUpdateTablePresetArgs,
    TablePresetRemoveResult,
    MutationRootRemoveTablePresetsArgs
  >({
    typename: "TablePreset",
    operations: {
      list: TablePresetListQuery,
      getById: undefined,
      create: CreateTablePresetMutation,
      update: UpdateTablePresetMutation,
      remove: RemoveTablePresetsMutation,
      link: undefined,
    },
    resultMap: tablePresetResultMap,
    mapRemovedIds: (variables) => variables.ids,
    listQueryVariables,
    listQueryOptions,
  })

  const columns = computed(() =>
    columnDefs.filter(
      (c) => !c.requiredPermission || sessionStore.hasRoles?.([c.requiredPermission])
    )
  )
  const selectedColumnKeys = ref<Set<string>>(new Set())
  const selectedColumns = computed({
    get: () =>
      [...selectedColumnKeys.value]
        .map((key) => columns.value.find((c) => c.key === key))
        .filter((c) => !!c),
    set: (columns) => {
      selectedColumnKeys.value = new Set(columns.map((c) => c.key))
    },
  })
  const selectedColumnFields = computed(
    () => new Set(selectedColumns.value.flatMap((c) => (c.fields as string[]) ?? [c.key]))
  )

  const activeInitialColumns = columnDefs.filter((col) => col.active ?? true)
  selectedColumns.value = activeInitialColumns

  const initialColumnPresetsLoaded = ref(false)
  api.whenListResultAvailable.then(() => (initialColumnPresetsLoaded.value = true))

  const columnsPresets = computed<TablePreset[]>(() => api.listResult?.tablePresets.items || [])

  const selectedColumnsPresetId = ref<string | undefined>()
  const selectedColumnsPreset = computed({
    get: () => columnsPresets.value.find((p) => p.id === selectedColumnsPresetId.value),
    set: (preset) => (selectedColumnsPresetId.value = preset?.id),
  })

  let initialDefaultSet = false
  watch(
    columnsPresets,
    () => {
      if (
        selectedColumnsPresetId.value &&
        !columnsPresets.value?.find((p) => p.id === selectedColumnsPresetId.value)
      )
        selectedColumnsPresetId.value = undefined

      if ((columnsPresets.value ?? []).length === 0 || initialDefaultSet) return

      const defaultColumnsPreset =
        columnsPresets.value.find((p) => p.isUserDefault) ??
        columnsPresets.value.find((p) => p.isGlobalDefault)

      if (defaultColumnsPreset) selectedColumnsPreset.value = defaultColumnsPreset

      initialDefaultSet = true
    },
    { immediate: true }
  )

  watch(
    selectedColumnsPreset,
    (preset) => {
      selectedColumnKeys.value = new Set(
        preset
          ? preset.columns.flatMap((key) => columnDefs.find((c) => c.key === key)?.key ?? [])
          : activeInitialColumns.map((c) => c.key)
      )
    },
    { immediate: true }
  )

  const { mutate: setDefaultPreset, loading: setDefaultLoading } = useMutation<
    SetDefaultTablePresetResult,
    MutationRootSetDefaultPresetArgs
  >(SetDefaultTablePresetMutation, {
    errorPolicy: "all",
    update: api.prepareListCacheReducer((cachedQuery, _data, variables) => {
      const list = tablePresetResultMap.getList(cachedQuery)
      for (const item of list.items) item.isUserDefault = item.id === variables.id
      return cachedQuery
    }),
  })

  const { mutate: unsetDefaultPreset, loading: unsetDefaultLoading } = useMutation<
    UnsetDefaultTablePresetResult,
    MutationRootUnsetDefaultPresetArgs
  >(UnsetDefaultTablePresetMutation, {
    errorPolicy: "all",
    update: api.prepareListCacheReducer((cachedQuery) => {
      const list = tablePresetResultMap.getList(cachedQuery)
      for (const item of list.items) item.isUserDefault = false
      return cachedQuery
    }),
  })

  api.addRemoveReducer(api.getListFilterRemoveReducer((ids) => (item) => !ids.includes(item.id)))

  const defaultLoading = computed(() => setDefaultLoading.value || unsetDefaultLoading.value)

  async function create(name: string, columns: string[]): MutateResult<TablePresetCreateResult> {
    if (!table) throw Error("Table is not provided!")
    const result = await api.create({ name, table, columns })
    selectedColumnsPresetId.value = result?.data?.createTablePreset.id
    return result
  }

  async function setDefault(id: string): MutateResult<SetDefaultTablePresetResult> {
    if (!table) throw Error("Table is not provided!")
    return await setDefaultPreset({ table, id })
  }

  async function unsetDefault(): MutateResult<UnsetDefaultTablePresetResult> {
    if (!table) throw Error("Table is not provided!")
    return await unsetDefaultPreset({ table })
  }

  function nameExists(name: string, exclude: TablePreset[] = []) {
    const excludeIds = exclude.map((p) => p.id)
    return columnsPresets.value.some((p) => !excludeIds.includes(p.id) && p.name === name)
  }

  function toggleColumn(column: Column<TData, TMappedData>) {
    if (![...selectedColumnKeys.value].some((key) => key === column.key))
      selectedColumnKeys.value.add(column.key)
    else if (
      [...selectedColumnKeys.value].filter((key) => columns.value.some((c) => c.key === key))
        .length > 1
    )
      selectedColumnKeys.value = new Set(
        [...selectedColumnKeys.value].filter((key) => key !== column.key)
      )
  }

  function setSelectedColumns(columns: Column<TData, TMappedData>[]) {
    selectedColumns.value = columns
  }

  function setSelectedColumnsPreset(preset?: TablePreset) {
    selectedColumnsPreset.value = preset
  }

  const requestedFields = ref<Set<string>>(new Set())
  const queryFields = ref<string>("")

  // resets the query by removing the @client directive
  function resetQuery() {
    const fields = selectedColumnFields.value

    queryFields.value = generateGQL(fields)
    requestedFields.value = fields
  }

  return reactive({
    column: {
      table,

      initialColumnPresetsLoaded,
      activeInitialColumns,
      selectedColumnKeys,
      selectedColumns,
      columns,
      columnsPresets,
      selectedColumnsPresetId,
      selectedColumnsPreset,

      nameExists,
      toggleColumn,
      setSelectedColumns,
      setSelectedColumnsPreset,

      api,
      defaultLoading,
      create,
      setDefault,
      unsetDefault,

      requestedFields,
      selectedColumnFields,
      queryFields,
      resetQuery,
    },
  })
}
