import merge from "lodash/merge"
import { watch } from "vue"

import { useFilterPreset } from "./useTableFilterPresetConfiguration"
import { useColumnPreset } from "./useTablePresetConfiguration"

import { type Table, type TableFilterTable } from "@/graphql/types"
import { useSessionStore } from "@/store/session"
import { type Column, type FilterableColumn } from "@/types"
import { buildNestedObject, objectToGQLString } from "@/utils/gqlbuilder"

export type TableConfiguration<TData, TMappedData = TData> = ReturnType<
  typeof usePreset<TData, TMappedData>
>

export function usePreset<TData, TMappedData = TData>(
  table: Table | undefined,
  columnDefs: Column<TData, TMappedData>[],
  listRouteName: string | string[],
  tableFilter?: TableFilterTable | undefined,
  filterableColumnDefs?: FilterableColumn<TData, TMappedData>[]
) {
  const sessionStore = useSessionStore()

  const { column } = useColumnPreset<TData, TMappedData>(table, columnDefs, listRouteName)
  const { filter } = useFilterPreset<TData, TMappedData>(
    tableFilter,
    filterableColumnDefs ?? [],
    listRouteName
  )

  watch(
    () => column.selectedColumnKeys,
    () => updateQueryFields(),
    { immediate: true, deep: true }
  )

  watch(
    () => sessionStore.institutionFilter,
    (context) => {
      if (context && context?.institution) updateQueryFields([], false)
    },
    { deep: true }
  )

  function updateQueryFields(addFields: string[] = [], client = true) {
    if (!column.initialColumnPresetsLoaded) return

    const filterableCols = (filterableColumnDefs ?? []).flatMap((col) => col.fields || col.dataKey)
    const fields = new Set([...column.selectedColumnFields, ...filterableCols, ...addFields])
    if (fields.size === 0) return

    const oldFields = buildNestedObject(column.requestedFields, client)
    const newFields = buildNestedObject([...fields].filter((f) => !column?.requestedFields.has(f)))

    column.queryFields = objectToGQLString(merge(oldFields, newFields))
    column.requestedFields = fields
  }

  function resetQuery() {
    column.resetQuery()
  }

  return {
    column,
    filter,
    updateQueryFields,
    resetQuery,
  }
}
